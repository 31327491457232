import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'form-help-text, [cdxFormHelpText]',
	template: `
		<ng-content></ng-content>
	`,
	styleUrls: ['./form-help-text.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormHelpTextComponent {}
