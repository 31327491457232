export const APP_ROUTES = {
	edr: {
		TermsAndConditions: 'https://www.everydayrewards.co.nz/terms-and-conditions',
		Privacy: 'https://www.everydayrewards.co.nz/privacy-policy',
		ContactUs: 'https://www.everydayrewards.co.nz/contact-us',
		Help: 'https://www.everydayrewards.co.nz/help',
		App: 'https://www.everydayrewards.co.nz/app',
		Partners: {
			base: 'https://www.everydayrewards.co.nz/partners',
		},
	},
	cdx: {
		LiquorLicences: 'https://www.woolworths.co.nz/shop/content/liquor-licences',
		HolidaysActRemediation: 'https://www.woolworths.co.nz/shop/content/holidays-act-remediation',
		Privacy: 'https://www.woolworths.co.nz/info/privacy',
		TermsAndConditions: 'https://www.woolworths.co.nz/info/terms-and-conditions',
		FAQs: 'https://www.woolworths.co.nz/shop/content/faqs',
		ContactUs: 'https://www.woolworths.co.nz/about-us/contact-us',
	},
};
