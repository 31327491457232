import { AfterContentInit, Component, HostBinding, OnDestroy, OnInit, Renderer2, isDevMode } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, RouterOutlet } from '@angular/router';
import {
	ApiService,
	AppService,
	AppSettingsService,
	BreakPointService,
	ShopperService,
} from '@woolworthsnz/styleguide';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { LoadingService } from './utils/loading.service';
import { delay, filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as Actions from './state/app.actions';
import { useOIDCTheme, getTheme, hideHeaderAndFooter, clientId, hasValidGigyaCache } from './utils';
import { slideInAnimation } from './app.animations';
import { EnvService, EnvServiceProvider } from './env.service';
import { AppInsightLoggingService } from './utils/services/appInsightLogging.service';
import { Title } from '@angular/platform-browser';
import { Client, createInstance } from '@optimizely/optimizely-sdk';
import { Observable, throwError } from 'rxjs';
import { ThemeService } from './utils/services/themes.service';
declare const window: any;
@Component({
	selector: 'registration-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [ApiService, EnvServiceProvider],
	animations: [slideInAnimation],
})
export class AppComponent implements OnInit, AfterContentInit, OnDestroy {
	@HostBinding('class.oidc-theme') isOIDCEnabled = useOIDCTheme();
	loading = false;
	themeLoading = true;
	currentURL: string;
	redirectingURL = '/account/magic-link-expired?nextUI=true';
	mainNav: { url: string; text: string; subnav?: boolean }[] = [];
	mobileNav: { url: string; text: string }[] = [];
	optimizelyClient: Client | null | undefined;
	themeArr: any[];
	selectedTheme = 'cdx';
	showThemeSelector = isDevMode();
	themeConfigs$: Observable<string>;
	hideHeaderAndFooter = hideHeaderAndFooter();

	constructor(
		private env: EnvService,
		private appService: AppService,
		private router: Router,
		private _loading: LoadingService,
		private readonly store: Store,
		private logService: AppInsightLoggingService,
		private appSettingsService: AppSettingsService,
		private breakpointService: BreakPointService,
		private titleService: Title,
		private tealiumService: TealiumUtagService,
		private shopperService: ShopperService,
		private themeService: ThemeService,
		private renderer2: Renderer2,
		private appInsight: AppInsightLoggingService
	) {
		this.themeArr = [
			{
				text: 'EDR',
				value: 'edr',
			},
			{
				text: 'CDX',
				value: 'cdx',
			},
		];

		// Interim redirects start
		const urlParams = new URLSearchParams(window.location.search);
		const otp = urlParams.get('o');
		const magicLinkEmail = urlParams.get('e');
		const signature = urlParams.get('s');
		const theme = urlParams.get('c');
		const tealiumClient = urlParams.get('t');
		const action = urlParams.get('action');
		const client = urlParams.get('client');
		const isMobileMagiclink = otp && magicLinkEmail && signature;
		if (client === 'edr' || theme === 'edr') {
			this.selectedTheme = 'edr';
		}

		if (isMobileMagiclink) {
			this.router.navigateByUrl(`/account/magic-link?o=${otp}&e=${magicLinkEmail}&s=${signature}`);
			sessionStorage.setItem('tealiumClient', tealiumClient);
		}

		if (action === 'resetPassword') {
			const redirect_url = urlParams.get('redirectUrl');
			this.router.navigateByUrl(`/account/reset-password?redirectUrl=${redirect_url ?? ''}`);
		}

		if (action === 'displayError') {
			const erroMsg = urlParams.get('erroMsg');
			this.router.navigateByUrl(`/error?message=${erroMsg ?? ''}`);
		}

		if (action === 'updatePassword') {
			const redirect_url = urlParams.get('redirectUrl');
			const token = urlParams.get('token');

			if (redirect_url) {
				sessionStorage.setItem('updatePasswordRedirectUrl', redirect_url);
			}
			this.router.navigateByUrl(
				`/account/reset-password/set-password?redirectUrl=${redirect_url ?? ''}&&token=${token ?? ''}`
			);
		}
		// Interim redirects end

		if (getTheme() === 'edr' || clientId() === 'edr_app') {
			this.selectedTheme = 'edr';
		}
		this.themeConfigs$ = this.themeService.configs;

		this.themeService.setTheme(this.selectedTheme, this.renderer2);

		if (this.env.appInsightInstructmentKey) {
			this.logService.initiailize();
		}

		const sdkKey = window?.BOOTSTRAP_DATA?.optimizelyEnvironment;
		if (!sdkKey) {
			const errorMessage = 'No Optimizely SDK Key found';
			this.logService.logEvent(errorMessage);
			throwError(() => new Error(errorMessage));
		}

		this.optimizelyClient = createInstance({
			sdkKey,
			eventBatchSize: 10,
			eventFlushInterval: 1000,
		});

		if (!this.optimizelyClient) {
			const errorMessage = 'Error creating optimizelyClient instance';
			this.logService.logEvent(errorMessage);
			throwError(() => new Error(errorMessage));
		}

		if (window && window.__env && window.__env.Tealium) {
			this.tealiumService.setConfig(window.__env.Tealium);
		}

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => {
					let route: ActivatedRoute = this.router.routerState.root;
					let routeTitle = '';
					while (route?.firstChild) {
						route = route.firstChild;
					}
					if (route.snapshot.data['title']) {
						routeTitle = route?.snapshot.data['title'];
					}
					return routeTitle;
				})
			)
			.subscribe((title: string) => {
				if (title) {
					if (getTheme() === 'edr') {
						this.titleService.setTitle(`${title} | Everyday Rewards`);
					} else if (getTheme() === 'demo_cdx_edr' || getTheme() === 'myCountdownApp' || getTheme() === 'trader') {
						this.titleService.setTitle(`${title} | Woolworths NZ - Online Grocery Shopping - Formerly Countdown`);
					} else {
						this.titleService.setTitle(`${title}`);
					}
				}
			});

		this.router.events.subscribe((routing) => {
			if (routing instanceof NavigationEnd) {
				this.currentURL = routing.url;
			}
		});

		if (!hasValidGigyaCache() && !isDevMode() && !action && !isMobileMagiclink) {
			this.appInsight?.logEvent('missing-cache');
			if (this.themeService.currentTheme === 'edr') {
				return (window.location.href = this.env.update().edrURL);
			}
			return (window.location.href = this.env.update().shopURL);
		}
	}

	ngOnInit() {
		setTimeout(() => {
			// Some clients such as CSX and Bunch do not pass us their ID
			this.tealiumService.setSharedData({
				client_id: clientId() ?? getTheme() ?? 'CSX, Bunch or unknown',
			});
			this.trackPageView();
			this.initPageViews();
		}, 1000);
		this.listenToLoading();

		this.mobileNav = this.appSettingsService.getSetting('navs')?.mobileNav;
		this.mainNav = this.appSettingsService.getSetting('navs')?.main;

    globalThis.profileUpdateFormEnabled = true;

		// fix giyga 10 login time out limit
		setInterval(() => window.location.reload(), 9 * 60 * 1000);
	}

	changeTheme(theme) {
		this.themeService.setTheme(theme, this.renderer2);
	}

	ngAfterContentInit() {
		window.addEventListener('beforeunload', () => {
			this.store.dispatch(Actions.resetUiError());
		});

		window.addEventListener('popstate', () => {
			this.store.dispatch(Actions.resetUiError());
		});
	}

	ngOnDestroy() {
		this.store.dispatch(Actions.resetUiError());
	}

	/**
	 * Listen to the loadingSub property in the LoadingService class. This drives the
	 * display of the loading spinner.
	 */
	listenToLoading(): void {
		this._loading.loadingSub.pipe(delay(0)).subscribe((loading) => {
			this.loading = loading;
		});
		this.themeService.themeLoading.pipe(delay(0)).subscribe((loading) => {
			this.themeLoading = loading;
		});
	}

	prepareRoute(outlet: RouterOutlet) {
		if (this.breakpointService.isSmallDevice$.getValue()) {
			return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
		}
		return;
	}

	initPageViews() {
		this.router.events.subscribe((routerEvent) => {
			if (routerEvent instanceof NavigationEnd) {
				this.trackPageView();
			}
			this.appService.checkRouterEvent(routerEvent as RouterEvent);
		});
	}

	trackPageView() {
		const scvid = this.shopperService.getAnalyticsData()?.customer_ols_scvid;
		this.tealiumService.view({
			tealium_event: 'page_view',
			scvid,
		});
	}
}
