<div class="magic-link-section">
	<section
		class="main-section magic-link-section"
		*ngIf="(verifyMagicLinkFailed$ | async) || (validateCustomerFailed$ | async)"
	>
		<h1>This link has expired</h1>
		<div class="form-content">
			<p>Your password-free login link has expired. Please request a new one below.</p>
		</div>

		<div class="form-actions">
			<button
				cdxButton
				class="submit-button"
				(click)="resendMagicLink()"
				value="Submit"
				tabindex="0"
				data-cy="'submit'"
				data-testid="submitButton "
			>
				Email me a new link
			</button>
		</div>
	</section>
	<section
		class="main-section loading"
		*ngIf="loading && !((verifyMagicLinkFailed$ | async) || (validateCustomerFailed$ | async))"
	>
		<div class="form-content loading">
			<h3>Loading...</h3>
		</div>
	</section>
</div>
