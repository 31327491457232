import { Component, AfterViewInit, OnInit, Input, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { FormService, passwordValidator } from '@woolworthsnz/form';
import { passwordMatchingValidator } from '../../utils/validators/password.matches.validator';
import { appStateSelector, deviceTypeSelector, emailSelector } from '../../state/app.selectors';
import { Store } from '@ngrx/store';
import * as Actions from '../../state/app.actions';
import { AvailableApps } from '../../utils/type/registration.type';
import { Observable } from 'rxjs';
import { AppState, UpdatePassword } from '../../state/app.state';
import { EnvService } from '../../env.service';
import { WINDOW, CustomWindow } from '@woolworthsnz/styleguide';
import { ThemeService } from '../../utils/services/themes.service';
import { Router } from '@angular/router';
import { addUtmParameters } from '../../utils';
@Component({
	selector: 'registration-set-new-password',
	templateUrl: './set-new-password.component.html',
	styleUrls: ['./set-new-password.component.scss'],
})
export class SetNewPasswordComponent implements OnInit, AfterViewInit {
	@Input() passwordLabel = 'New Password *';
	@Input() submitButtonValue = 'Reset Password';
	appstate$: Observable<AppState>;
	themeConfigs$: Observable<string>;
	deviceType$: AvailableApps;
	email$: string;
	title: string;
	form: FormGroup;
	password$: string;
	type: 'text' | 'password' = 'password';
	parentFormGroup: FormGroup;

	constructor(
		private fb: UntypedFormBuilder,
		public formService: FormService,
		private readonly store: Store,
		private themeConfigs: ThemeService,
		@Inject(EnvService) private _env: EnvService,
		@Inject(WINDOW) private window: CustomWindow,
		private router: Router
	) {
		this.store.select(emailSelector).subscribe((email) => (this.email$ = email));
		this.store.select(deviceTypeSelector).subscribe((deviceType) => (this.deviceType$ = deviceType));
		this.appstate$ = this.store.select(appStateSelector);
		this.themeConfigs$ = this.themeConfigs.configs;
	}

	ngOnInit() {
		addUtmParameters(this.router);
		const { required } = Validators;
		const formControls = {
			password: ['', [required, passwordValidator(), passwordMatchingValidator('confirmPassword', true)]],
			confirmPassword: ['', [required, passwordMatchingValidator('password')]],
		};
		this.form = this.fb.group(formControls, { updateOn: 'change' });
	}

	ngAfterViewInit() {
		setTimeout(() => {
			const input: HTMLInputElement = document.querySelector('#password');
			input?.focus();
		}, 500);
	}

	onSubmit() {
		if (this.form.valid) {
			const { password } = this.form.value;
			const urlParams = new URLSearchParams(window.location.search);
			const token = urlParams.get('token');
			globalThis.grecaptcha.enterprise.ready(() => {
				globalThis.grecaptcha.enterprise
					.execute(this._env.reCaptchaKey, { action: 'update_password' })
					.then((reCaptchaKeyToken) => {
						globalThis.v3token = reCaptchaKeyToken;
						const data: UpdatePassword = {
							password,
							token,
						};
						this.store.dispatch(Actions.updatePassword(data));
					});
			});
		}
	}

	showResendEmail() {
		if (this.title === 'Reset password link expired') {
			return true;
		} else {
			return false;
		}
	}

	get passwordCharMismatch() {
		return this.form.get('password').errors?.['charMismatch'];
	}

	get passwordMinimumLength() {
		return this.form.get('password').errors?.['minlength'];
	}
	get containsUpperCase() {
		return this.form.get('password').value.match(/[A-Z]/);
	}
	get containsLowercase() {
		return this.form.get('password').value.match(/[a-z]/);
	}
	get containsNumber() {
		return this.form.get('password').value.match(/[0-9]/);
	}
	get containsSpecialCharacter() {
		return this.form.get('password').value.match(/([\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]|\s)/);
	}

	// Cannot use form-password-input for the second password input because the formControl will be the same as the first input
	// Using form-input-with-icon-button instead and using <button> for the button, these functions are for the button

	get state() {
		return this.type && this.type === 'text' ? 'Hide' : 'Show';
	}

	get buttonLabel() {
		return this.state + ' password';
	}

	onButtonChange($event: { stopPropagation: () => void; preventDefault: () => void }) {
		$event.stopPropagation();
		$event.preventDefault();
		return false;
	}

	toggleInputType() {
		if (this.type && this.type === 'text') {
			this.type = 'password';
		} else {
			this.type = 'text';
		}
	}
}
