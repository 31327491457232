import { Injectable, Inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WINDOW, CustomWindow } from '@woolworthsnz/styleguide';
import { JsBridgeSessionInfo, JsBridgeEvents, JsBridgeCookieInfo } from '../';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class JsBridgeService {
	constructor(@Inject(WINDOW) private window: CustomWindow) {}

	broadcastNativeEvent(type: JsBridgeEvents, payload: JsBridgeSessionInfo) {
		this.window.webkit?.messageHandlers?.AppEventBus?.postMessage({ type, payload });
		this.window.AppEventBus?.postMessage(JSON.stringify({ type, payload }));
	}

	broadcastBrowserEvent(type: JsBridgeEvents, payload: JsBridgeCookieInfo) {
		this.window.parent?.postMessage({ type, payload }, '*');
	}
}
