import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { emailSelector, sessionSelector } from './app.selectors';
import { EnvService } from '../env.service';
import { SessionState } from './app.state';

@Injectable({
	providedIn: 'root',
})
export class EmailGuard {
	email$: string;

	constructor(private router: Router, private readonly store: Store) {
		this.store.select(emailSelector).subscribe((email) => (this.email$ = email));
	}

	canActivate(): boolean {
		if (this.email$) {
			return true;
		} else {
			this.router.navigate(['/enter-email']);
			return false;
		}
	}
}

@Injectable({
	providedIn: 'root',
})
export class ProdGuard {
	constructor(private router: Router, private env: EnvService) {}
	canActivate(): boolean {
		const env = this.env.update();
		if (env._envName === 'PROD') {
			this.router.navigate(['/enter-email']);
			return false;
		} else {
			return true;
		}
	}
}

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	session$: SessionState;

	constructor(private router: Router, private readonly store: Store) {
		this.store.select(sessionSelector).subscribe((session) => (this.session$ = session));
	}

	canActivate(): boolean {
		if (this.session$.token) {
			return true;
		} else {
			this.router.navigate(['/enter-email']);
			return false;
		}
	}
}
