import { ChangeDetectionStrategy, Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormService } from '@woolworthsnz/form';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as Actions from '../../state/app.actions';
import {
	emailSelector,
	deviceTypeSelector,
	codeVerifierSelector,
	appStateSelector,
	loginFailedTitleSelector,
} from '../../state/app.selectors';
import { AvailableApps, Login, LoginMagicLink } from '../../utils/type/registration.type';
import { CryptoService } from '../../utils/services/cryptoService.service';
import { AppSettings } from '../../app.settings';
import { EnvService, EnvServiceProvider } from '../../env.service';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { APP_ROUTES } from '../../../themes/app.routes';
import { ThemeService } from '../../utils/services/themes.service';
import { AppState } from '../../state/app.state';
import { addUtmParameters, getTheme, isMobileWebview } from '../../utils';

declare const grecaptcha: any;
@Component({
	selector: 'registration-enter-password',
	templateUrl: './enter-password.component.html',
	styleUrls: ['./enter-password.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [EnvServiceProvider],
})
export class EnterPasswordComponent implements OnInit, AfterViewInit {
	@Input() passwordLabel = 'Password *';
	@Input() submitButtonValue = 'Sign in';
	@Input() hasLoginAsParent = false;
	showAlert = true;
	email$: string;
	deviceType$: AvailableApps;
	appstate$: Observable<AppState>;
	codeVerifier$: string;
	sendMagicLinkFailed$: Observable<string>;
	loginFailedTitle$: Observable<string>;
	loginServerError$: Observable<string>;
	form: UntypedFormGroup;
	themeConfigs$: Observable<string>;
	hideMagicLink: boolean;
	currentTheme: string;

	edrTermsAndConditionsLink = APP_ROUTES.edr.TermsAndConditions;
	cdxTermsAndConditionsLink = APP_ROUTES.cdx.TermsAndConditions;
	cdxPrivacyCentre = APP_ROUTES.cdx.Privacy;

	constructor(
		private env: EnvService,
		private fb: UntypedFormBuilder,
		public formService: FormService,
		private readonly store: Store,
		private cryptoService: CryptoService,
		private router: Router,
		private tealiumService: TealiumUtagService,
		private themeService: ThemeService
	) {
		this.store.select(emailSelector).subscribe((email) => (this.email$ = email));
		this.store.select(deviceTypeSelector).subscribe((deviceType) => (this.deviceType$ = deviceType));
		this.store.select(codeVerifierSelector).subscribe((codeVerifier) => (this.codeVerifier$ = codeVerifier));
		this.appstate$ = this.store.select(appStateSelector);
		this.themeConfigs$ = this.themeService.configs;
		this.loginFailedTitle$ = this.store.select(loginFailedTitleSelector);
	}

	ngOnInit() {
		addUtmParameters(this.router);
		const { required } = Validators;
		const formControls = {
			password: ['', [required]],
		};
		this.form = this.fb.group(formControls, { updateOn: 'submit' });
		this.hideMagicLink = isMobileWebview();
	}

	getEmailTheme() {
		if (getTheme() === 'edr') {
			return 'edr';
		} else {
			return 'cdx';
		}
	}

	sendMagicLink() {
		const currentTheme = this.getEmailTheme();
		const codeVerifier = this.cryptoService.generateCodeVerifier();
		const gigyaCache = sessionStorage.getItem('gigyaCache');
		const magicLink: LoginMagicLink = {
			codeVerifier,
			email: this.email$,
			deviceType: this.getEmailTheme(),
			state: gigyaCache,
			client: currentTheme,
		};
		grecaptcha.enterprise.ready(() => {
			grecaptcha.enterprise.execute(this.env.reCaptchaKey, { action: 'email_login' }).then((token: string) => {
				globalThis.v3token = token;
				this.store.dispatch(Actions.sendMagicLink(magicLink));
			});
		});
		this.tealiumService.link({
			tealium_event: 'ssu_magic_link',
		});
	}

	resetPassword() {
		this.router.navigate(['/account/reset-password']);
	}

	resendMagicLink() {
		this.sendMagicLink();
	}

	hideAlert() {
		this.showAlert = false;
	}

	ngAfterViewInit() {
		setTimeout(() => {
			const input: HTMLInputElement = document.querySelector('#password');
			input?.focus();
		}, 200);
	}

	onSubmit() {
		if (this.form.valid && this.deviceType$) {
			const loginData: Login = {
				email: this.email$,
				password: this.form.get('password').value,
				sessionInfoType: AppSettings.sessionInfoType[this.deviceType$],
			};
			grecaptcha.enterprise.ready(() => {
				grecaptcha.enterprise.execute(this.env.reCaptchaKey, { action: 'password_login' }).then((token: string) => {
					globalThis.v3token = token;
					this.store.dispatch(Actions.login(loginData));
				});
			});
		}
	}
}
