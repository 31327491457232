import { AbstractControl, ValidatorFn } from '@angular/forms';

export class RegExName {
	static NAME = `^[a-zA-Z-.,'\\s]*$`;
}

export const validateNameWithMask =
	(): ValidatorFn =>
	(control: AbstractControl): { [key: string]: any } | null => {
		const r = new RegExp(RegExName.NAME);
		const value = control.value;

		if (value != null) {
			if (value.trim().length === 0) {
				return { invalidName: control.value };
			} else {
				return r.test(value) ? null : { invalidName: control.value };
			}
		} else {
			return r.test(value) ? null : { invalidName: control.value };
		}
	};
