import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { StyleguideModule } from '@woolworthsnz/styleguide';
import {
	AutocompleteComponent,
	DobInputComponent,
	DropdownComponent,
	EmailPasswordInputComponent,
	FieldsetComponent,
	FormComponent,
	FormErrorComponent,
	FormHelpTextComponent,
	FormOptionalTextComponent,
	InputComponent,
	InputErrorComponent,
	InputWithButtonComponent,
	InputWithIconButtonComponent,
	PasswordInputComponent,
	RadioGroupComponent,
	SelectComponent,
	SelectionTileComponent,
	SelectOptionComponent,
	SwitchComponent,
} from './components';
import { AutoFocusDirective, InputAccessorDirective, InvalidTypeDirective, TabbingClickDirective } from './directives';
import { FormService } from './services';

const components = [
	AutocompleteComponent,
	DobInputComponent,
	DropdownComponent,
	EmailPasswordInputComponent,
	FieldsetComponent,
	FormComponent,
	FormErrorComponent,
	FormHelpTextComponent,
	FormOptionalTextComponent,
	InputComponent,
	InputErrorComponent,
	InputWithButtonComponent,
	InputWithIconButtonComponent,
	PasswordInputComponent,
	RadioGroupComponent,
	SelectComponent,
	SelectOptionComponent,
	SelectionTileComponent,
	SwitchComponent,
];

const directives = [AutoFocusDirective, InvalidTypeDirective, InputAccessorDirective, TabbingClickDirective];
@NgModule({
	declarations: [...components, ...directives],
	exports: [...components, ...directives],
	imports: [CommonModule, OverlayModule, PortalModule, ReactiveFormsModule, StyleguideModule],
	providers: [FormService],
})
export class FormModule {}
