import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightLoggingService } from './appInsightLogging.service';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
	constructor(private appInsightLoggingService: AppInsightLoggingService) {
		super();
	}

	handleError(error: Error) {
		this.appInsightLoggingService.logException(error);
		console.error(error);
	}
}
