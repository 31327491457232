import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'registration-reset-password-sent-email',
	templateUrl: './reset-password-sent-email.component.html',
	styleUrls: ['./reset-password-sent-email.component.scss'],
})
export class ResetPasswordSentEmailComponent implements OnInit {
	ngOnInit() {
		return null;
	}
}
