<section class="main-section">
	<form (ngSubmit)="onSubmit()" [formGroup]="form" data-testid="registerForm">
		<h1>{{ this.updateMode ? 'Confirm' : 'Enter' }} your details</h1>
		<cdx-alert
			[type]="'error'"
			class="error-alert"
			title="{{ (themeConfigs$ | async).serverErrorTitle }}"
			description="{{ (themeConfigs$ | async).serverErrorText }}"
			icon="round-checked-filled"
			[candismiss]="true"
			*ngIf="(appState$ | async).updateCustomerFailed"
		>
		</cdx-alert>
		<fieldset class="name-fieldset">
			<form-input
				autocomplete="off"
				formControlName="firstName"
				data-testid="firstName"
				class="firstName-input"
				maxlength="40"
			>
				<label i18n="@@register-First" for="firstName" label>First name <span class="asterisk">*</span></label>
				<form-input-error validation control="firstName">
					<p [cdxInvalidType]="'*'" i18n="@@register-invalidFirstname" data-testid="firstNameError">
						Please enter a valid first name.
					</p>
				</form-input-error>
			</form-input>

			<form-input
				autocomplete="off"
				formControlName="lastName"
				class="lastName-input"
				data-testid="lastName"
				maxlength="40"
			>
				<label i18n="@@register-lastName" for="lastName" label>Last name <span class="asterisk">*</span></label>
				<form-input-error validation control="lastName">
					<p [cdxInvalidType]="'*'" i18n="@@register-invalidLastName" data-testid="lastNameError">
						Please enter a valid last name.
					</p>
				</form-input-error>
			</form-input>
		</fieldset>
		<fieldset class="date-of-birth-fieldset">
			<label i18n="@@register-dobLabel" for="dateOfBirth" label> Date of birth <span class="asterisk">*</span></label>
			<p class="helper-text" cdxFormHelpText i18n="@@register-dobInputHelperText">
				Some products require age verification.
			</p>
			<form-dob-input formControlName="dateOfBirth" [validateMinimumAge]="true" data-testid="dob">
				<form-input-error validation control="dateOfBirth">
					<p [cdxInvalidType]="'underage'" i18n="@@register-underageErrorMessage" data-testid="shopperUnderage">
						You must be 13 years of age or older to register.
					</p>
					<p [cdxInvalidType]="'dateInvalid'" i18n="@@register-invalidDateErrorMessage" data-testid="dateInvalidError">
						The entered date of birth is not a valid date of birth.
					</p>
					<p [cdxInvalidType]="'required'" i18n="@@register-invalidDateErrorMessage" data-testid="dateInvalidError">
						Please enter a valid date of birth.
					</p>
				</form-input-error>
			</form-dob-input>
		</fieldset>

		<fieldset>
			<label i18n="@@register-mobile" label> Mobile number <span class="asterisk">*</span></label>
			<p class="helper-text" cdxFormHelpText i18n="@@register-dobInputHelperText">
				Required to receive SMS delivery updates from our partners.
			</p>
			<form-input
				formControlName="mobile"
				maxlength="13"
				data-testid="mobile"
				[onKeyupMaskingFn]="formatMobileNumber"
				placeholder="e.g. 021 234 567"
				inputType="text"
				inputMode="numeric"
				class="mobile-input"
			>
				<form-input-error validation control="mobile" placeholder="e.g. 021 XXX">
					<p [cdxInvalidType]="'*'" i18n="@@register-invalidMobile" data-testid="mobileError">
						Please enter a valid Mobile number.
					</p>
				</form-input-error>
			</form-input>
		</fieldset>
		<div class="communication-settings" *ngIf="!updateMode">
			<ng-container *ngIf="(themeConfigs$ | async).name === 'edr'; then edrCheckbox; else cdxCheckbox"></ng-container>
			<ng-template #edrCheckbox>
				<fieldset cdxFieldset legend="''" [i18n]="'@@register-settings'" hideLegend="true">
					<form-input formControlName="edrSetting" inputType="checkbox">
						<label class="checkbox-label" for="edrSetting" label>
							Please send me personalised offers and updates from Everyday Rewards.
						</label>
					</form-input>
					<form-input formControlName="countdownSetting" inputType="checkbox">
						<label class="checkbox-label" for="countdownSetting" label>
							Please send me personalised offers and updates from Woolworths.
						</label>
					</form-input>
				</fieldset>
			</ng-template>
			<ng-template #cdxCheckbox>
				<fieldset cdxFieldset legend="''" [i18n]="'@@register-settings'" hideLegend="true">
					<form-input formControlName="countdownSetting" inputType="checkbox">
						<label class="checkbox-label" for="countdownSetting" label>
							Please send me personalised offers and updates from Woolworths.
						</label>
					</form-input>
					<form-input formControlName="edrSetting" inputType="checkbox">
						<label class="checkbox-label" for="edrSetting" label>
							Please send me personalised offers and updates from Everyday Rewards.
						</label>
					</form-input>
				</fieldset>
			</ng-template>
			<p class="change-preferences-text">This can be changed at any time in your preferences.</p>
		</div>

		<fieldset *ngIf="!updateMode">
			<ng-container
				*ngIf="(themeConfigs$ | async).name === 'edr'; then edrTermsAndConditions; else cdxTermsAndConditions"
			></ng-container>
			<ng-template #edrTermsAndConditions>
				<p class="terms-and-conditions-text">
					I've read and accepted
					<a class="link-text-registration" target="_blank" [href]="edrTermsAndConditionsLink">Everyday Rewards T&Cs</a
					>,
					<a class="link-text-registration" target="_blank" [href]="cdxTermsAndConditionsLink"
						>Woolworths NZ Online Shopping T&Cs</a
					>, and
					<a class="link-text-registration" target="_blank" [href]="cdxPrivacyCentre">Woolworths NZ Privacy Policy</a>.
				</p>
			</ng-template>
			<ng-template #cdxTermsAndConditions>
				<p class="terms-and-conditions-text">
					I've read and accepted
					<a class="link-text-registration" target="_blank" [href]="cdxTermsAndConditionsLink"
						>Woolworths NZ Online Shopping T&Cs</a
					>,
					<a class="link-text-registration" target="_blank" [href]="edrTermsAndConditionsLink">Everyday Rewards T&Cs</a>
					and the
					<a class="link-text-registration" target="_blank" [href]="cdxPrivacyCentre">Woolworths NZ Privacy Policy</a>.
				</p>
			</ng-template>
		</fieldset>
		<div class="form-actions">
			<button
				cdxButton
				class="submit-button"
				value="{{ this.updateMode ? 'Update' : 'Create my account' }}"
				tabindex="0"
				data-cy="'submit'"
				data-testid="submit"
				[disabled]="!form.valid && !updateMode"
			>
				{{ this.updateMode ? 'Update' : 'Create my account' }}
			</button>
		</div>
	</form>
</section>
