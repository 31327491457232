import * as SHA256 from 'crypto-js/sha256';
import * as Base64 from 'crypto-js/enc-base64';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class CryptoService {
	constructor() {}

	// Algorithm based on OAuth 2.0 PKCE Flow
	// https://www.oauth.com/playground/authorization-code-with-pkce.html

	generateCodeVerifier = () => this.randomString(32);

	randomString = (len) => {
		const arr = new Uint8Array(len);
		const ran = window.crypto.getRandomValues(arr).toString();
		const str = this.base64Urlencode(ran);
		return str.substring(0, len);
	};

	base64Urlencode = (str: string) => this.urlEncode(btoa(str));

	urlEncode = (str: string) => str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');

	generateCodeChallenge = (verifier: string) => this.urlEncode(Base64.stringify(SHA256(verifier)));
}
