import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormService } from '@woolworthsnz/form';
import { emailSelector } from '../../state/app.selectors';
import { Router } from '@angular/router';
import { EnvServiceProvider } from '../../env.service';
import { addUtmParameters } from '../../utils';
@Component({
	selector: 'registration-magic-link-sent',
	templateUrl: './magic-link-sent.component.html',
	styleUrls: ['./magic-link-sent.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [FormService, EnvServiceProvider, FormGroupDirective],
})
export class MagicLinkSentComponent implements OnInit {
	@Input() submitButtonValue = 'Continue';

	email$: string;

	constructor(private readonly store: Store, private router: Router) {
		this.store.select(emailSelector).subscribe((email) => (this.email$ = email));
	}

	resendMagicLink() {
		this.router.navigateByUrl('/edr/enter-email');
	}

	ngOnInit() {
		addUtmParameters(this.router);
		return null;
	}
}
