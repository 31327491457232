import { APP_ROUTES } from '../../themes/app.routes';

export const FooterItems = [
	{
		label: 'Everyday Rewards App',
		id: 'everyday-rewards-app',
		url: `${APP_ROUTES.edr.App}`,
	},
	{
		label: 'Terms & Conditions',
		id: 'terms-conditions',
		url: `${APP_ROUTES.edr.TermsAndConditions}`,
	},
	{
		label: 'Privacy at Everyday Rewards',
		id: 'privacy',
		url: `${APP_ROUTES.edr.Privacy}`,
	},
	{
		label: 'Help',
		id: 'help',
		url: `${APP_ROUTES.edr.Help}`,
	},
	{
		label: 'Contact us',
		id: 'contact-us',
		url: `${APP_ROUTES.edr.ContactUs}`,
	},
	{
		label: 'Partners',
		id: 'partners',
		url: `${APP_ROUTES.edr.Partners.base}`,
	},
];
