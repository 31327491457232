import { AppState, State, SessionState, UserState, EncryptedState } from './app.state';
import { createSelector } from '@ngrx/store';

export const getAppState = (state: State) => state.app;
export const getAppSessionState = (state: State) => state.session;
export const getAppUserState = (state: State) => state.user;
export const getEncryptedState = (state: State) => state.encrypted;

export const appStateSelector = createSelector(getAppState, (state: AppState) => state);
export const emailSelector = createSelector(getAppUserState, (state: UserState) => state.email);
export const deviceTypeSelector = createSelector(getAppState, (state: AppState) => state.deviceType);
export const apiNotAvailableSelector = createSelector(getAppState, (state: AppState) => state.apiNotAvailable);
export const checkIfRegisteredFailedSelector = createSelector(
	getAppState,
	(state: AppState) => state.checkIfRegisteredFailed
);
export const registeredInactiveSelector = createSelector(getAppState, (state: AppState) => state.registeredInactive);
export const verifyMagicLinkFailedSelector = createSelector(
	getAppState,
	(state: AppState) => state.verifyMagicLinkFailed
);

export const sendMagicLinkFailedSelector = createSelector(getAppState, (state: AppState) => state.sendMagicLinkFailed);
export const sendForgotPasswordFailedSelector = createSelector(
	getAppState,
	(state: AppState) => state.sendForgotPasswordFailed
);
export const loginFailedSelector = createSelector(getAppState, (state: AppState) => state.loginFailed);
export const loginFailedTitleSelector = createSelector(getAppState, (state: AppState) => state.loginFailedTitle);
export const updatePasswordExpiredSelector = createSelector(
	getAppState,
	(state: AppState) => state.updatePasswordExpired
);
export const updatePasswordFailedSelector = createSelector(
	getAppState,
	(state: AppState) => state.updatePasswordFailed
);

export const updateCustomerFailedSelector = createSelector(
	getAppState,
	(state: AppState) => state.updateCustomerFailed
);
export const updateCustomerSuccessSelector = createSelector(
	getAppState,
	(state: AppState) => state.updateCustomerSuccess
);

export const activationStateSelector = createSelector(getAppState, (state: AppState) => state.activationState);
export const validateCustomerFailedSelector = createSelector(
	getAppState,
	(state: AppState) => state.validateCustomerFailed
);

export const userSelector = createSelector(getAppUserState, (state: UserState) => state);
export const createActivationFailedSelector = createSelector(
	getAppState,
	(state: AppState) => state.createActivationFailed
);

// session
export const sessionSelector = createSelector(getAppSessionState, (state: SessionState) => state);
export const tokenSelector = createSelector(getAppSessionState, (state: SessionState) => state.token);
export const cardNumberSelector = createSelector(
	getAppSessionState,
	(state: SessionState) => state.cards[0]?.cardNumber
);
export const cardStatusSelector = createSelector(getAppSessionState, (state: SessionState) => state.cards[0]?.result);
export const cvvSelector = createSelector(getAppSessionState, (state: SessionState) => state.cards[0]?.cvv);
export const alreadyLinkedToOtherEmailMsgSelector = createSelector(
	getAppSessionState,
	(state: SessionState) => state.otherEmail
);

// encrypted Data
export const encryptedStateSelector = createSelector(getEncryptedState, (state: EncryptedState) => state);
export const codeVerifierSelector = createSelector(getEncryptedState, (state: EncryptedState) => state.codeVerifier);
