import { TemplatePortal } from '@angular/cdk/portal';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalOverlayRef, ModalOverlayService, MultiSuggestion } from '@woolworthsnz/styleguide';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { InputWithIconButtonComponent } from '../input-with-icon-button/input-with-icon-button.component';
import { InputComponent } from '../input/input.component';

@UntilDestroy()
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'cdxAutocomplete',
	providers: [ModalOverlayService],
	selector: 'form-autocomplete',
	template: `
		<ng-content></ng-content>
		<ng-template cdkPortal #overlayTemplate="cdkPortal">
			<cdx-autocomplete-modal>
				<ng-container *ngIf="searchResults; else multiResults">
					<cdx-option-list
						[listItems]="searchResults"
						[displayKey]="displayKey"
						(optionSelect)="onOptionSelect($event)"
						(optionFocus)="onOptionFocus($event)"
						[idPrefix]="idPrefix"
					>
						<ng-content select="[footer]"></ng-content>
					</cdx-option-list>
				</ng-container>
				<ng-template #multiResults>
					<cdx-option-list-multi
						[listItems]="multiSearchResults"
						(optionSelect)="onOptionSelect($event)"
						(optionFocus)="onOptionFocus($event)"
						[idPrefix]="idPrefix"
					>
						<ng-content select="[footer]"></ng-content>
					</cdx-option-list-multi>
				</ng-template>
			</cdx-autocomplete-modal>
		</ng-template>
	`,
	styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input()
	set searchResults(results: any[]) {
		this.setResults(results);
	}

	@Input()
	set multiSearchResults(results: MultiSuggestion[]) {
		this.setResults(results, true);
	}

	_overlayVisible = false;
	set overlayVisible(v: boolean) {
		this._overlayVisible = v;
	}

	@ContentChild(InputComponent)
	autocompleteInput: InputComponent;
	@ContentChild(InputWithIconButtonComponent, { read: ElementRef })
	autocompleteInputWithIcon: ElementRef;
	@ViewChild('overlayTemplate')
	overlayTemplate: TemplatePortal<any>;

	@Input() formGroup: UntypedFormGroup;
	@Input() displayKey: string;
	@Input() valueKey: string;
	@Input() idPrefix: string;
	@Output() typedValue: EventEmitter<string> = new EventEmitter();
	@Output() optionSelect: EventEmitter<any> = new EventEmitter();
	@Output() optionFocus: EventEmitter<any> = new EventEmitter();
	@Output() resubscribe: EventEmitter<void> = new EventEmitter();

	@Input() debounceTime = 100;
	@Input() tolerance = 1; // The minimum chars before a search

	protected autoCompleteResults: any[];
	protected multiAutoCompleteResults: MultiSuggestion[];
	protected overlay: ModalOverlayRef;
	protected autocompleteInputTarget: any;

	private unsubscribedToSuggestions$ = new Subject(); // Used to ignore suggestions while the input is blurred
	private inputValue: string;
	private inputFormControl: any;
	private isOptionSelected = false;

	get searchResults() {
		return this.autoCompleteResults;
	}

	get multiSearchResults() {
		return this.multiAutoCompleteResults;
	}

	constructor(protected modalOverlayService: ModalOverlayService) {}

	ngOnInit(): void {
		this.modalOverlayService.state$.pipe(untilDestroyed(this)).subscribe((m) => (this.overlay = m.modal));
	}

	ngAfterViewInit(): void {
		this.subscribeToInputChanges();

		const inputFormControlName = this.autocompleteInput.formControlName;
		this.inputFormControl = this.formGroup.get(inputFormControlName);

		this.autocompleteInputTarget =
			this.autocompleteInputWithIcon?.nativeElement ?? this.autocompleteInput.input.nativeElement;
	}

	ngOnDestroy(): void {
		this.unsubscribedToSuggestions$.next(undefined);
		this.unsubscribedToSuggestions$.complete();

		this.closeOverlay();

		return;
	}

	closeOverlay() {
		if (this.overlay) {
			this.overlayVisible = false;
			this.overlay.close();
		}
	}

	isTermLongEnough = (e: any) => e.toString().length >= this.tolerance;

	subscribeToInputChanges(): void {
		this.autocompleteInput.focus.pipe(untilDestroyed(this)).subscribe(() => {
			this.subscribeToKeyupEvents();
		});

		this.subscribeToKeyupEvents();

		this.subscribeToAutocompleteBlur();
	}

	onOptionSelect(option: any) {
		const displayvalue = option.section ? option.option[this.displayKey] : option[this.displayKey];
		this.inputValue = displayvalue;
		this.inputFormControl.patchValue(displayvalue);
		this.isOptionSelected = true;

		if (this.autocompleteInput && this.autocompleteInput.input) {
			this.autocompleteInput.input.nativeElement.blur();
		}

		this.optionSelect.emit(option);
	}

	onOptionFocus(option: any) {
		this.optionFocus.emit(option);
	}

	unsubscribeToSearchSuggestions() {
		this.unsubscribedToSuggestions$.next(undefined); // we need to ignore search suggestions until the search input is in focus again
	}

	protected setResults(results: any[] | MultiSuggestion[], isMulti = false) {
		if (results) {
			if (isMulti) {
				this.multiAutoCompleteResults = results;
			} else {
				this.autoCompleteResults = results;
			}

			if (this.overlay) this.closeOverlay();

			if (results.length) {
				this.modalOverlayService.setState({
					element: this.autocompleteInputTarget,
					hasBackdrop: false,
					isConnectedElement: true,
					backdropClass: 'overlay--transparent',
					scrollStrategy: 'reposition',
					width: this.autocompleteInputTarget.offsetWidth,
				});
				this.overlayVisible = true;
				this.modalOverlayService.open({
					templateRef: this.overlayTemplate,
					skipTracking: true,
				});
			}
		}
	}

	private subscribeToAutocompleteBlur() {
		this.autocompleteInput.blur.pipe(untilDestroyed(this)).subscribe(() => {
			if (!this.isOptionSelected) {
				this.inputValue = '';
			}
			// the cdx-option-list select trigger is click, so for touch devices we need to leave the overlay up as long as the simulated click event post touch (300ms)
			setTimeout(() => {
				this.closeOverlay();
			}, 300);
		});
	}

	private subscribeToKeyupEvents() {
		this.autocompleteInput.keyup
			.pipe(
				filter((value) => this.isTermLongEnough(value)),
				debounceTime(this.debounceTime),
				untilDestroyed(this),
				// Ignore changes while navigating to search
				takeUntil(this.unsubscribedToSuggestions$)
			)
			.subscribe((value: any) => {
				// handle delete of value back to tolerance
				if (value.toString().length === this.tolerance && this.tolerance > 0) {
					this.inputValue = value;
					this.closeOverlay();
					return;
				}
				if (value === this.inputValue) return;
				this.inputValue = value;
				this.isOptionSelected = false;
				this.typedValue.emit(value);
			});
	}
}
