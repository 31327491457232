import { Component } from '@angular/core';
import { EnvService, EnvServiceProvider } from '../env.service';

@Component({
	selector: 'registration-env-config',
	templateUrl: './env-config.component.html',
	styleUrls: ['./env-config.component.scss'],
	providers: [EnvServiceProvider],
})
export class EnvConfigComponent {
	constructor(public env: EnvService) {}
}
