import { APP_ROUTES } from '../../themes/app.routes';

export const FooterItems = [
	{
		label: 'Liquor licences',
		id: 'liquior-licences',
		url: `${APP_ROUTES.cdx.LiquorLicences}`,
	},
	{
		label: 'Holidays Act remediation',
		id: 'holidays-act-remediation',
		url: `${APP_ROUTES.cdx.TermsAndConditions}`,
	},
	{
		label: 'Privacy centre',
		id: 'privacy-centre',
		url: `${APP_ROUTES.cdx.Privacy}`,
	},
	{
		label: 'Terms and conditions',
		id: 'terms-and-conditions',
		url: `${APP_ROUTES.cdx.TermsAndConditions}`,
	},
	{
		label: 'Contact us',
		id: 'contact-us',
		url: `${APP_ROUTES.cdx.ContactUs}`,
	},
];
