<section class="main-section">
	<h1>Enter your email</h1>
	<div>
		<p
			[hidden]="
				(appstate$ | async).checkIfRegisteredFailed ||
				(appstate$ | async).verifyMagicLinkFailed ||
				(appstate$ | async).registeredInactive
			"
		>
			{{ (themeConfigs$ | async).enterEmailText }}
			<a class="link-text-registration" target="_blank" [href]="(themeConfigs$ | async).learnMoreLink">Learn more</a>
		</p>
	</div>
	<cdx-alert
		[type]="'error'"
		class="error-alert"
		*ngIf="(appstate$ | async).checkIfRegisteredFailed"
		title="{{ (themeConfigs$ | async).serverErrorTitle }}"
		description="{{ (themeConfigs$ | async).serverErrorText }}"
		data-testid="checkIfRegisteredFailed"
	>
	</cdx-alert>
	<cdx-alert
		[type]="'error'"
		class="error-alert"
		*ngIf="(appstate$ | async).verifyMagicLinkFailed"
		[description]="(appstate$ | async).verifyMagicLinkFailed"
		data-testid="verifyMagicLinkFailed"
	>
	</cdx-alert>
	<cdx-alert
		[type]="'error'"
		class="error-alert"
		*ngIf="(appstate$ | async).registeredInactive"
		[title]="(appstate$ | async).registeredInactive"
		description="{{ (themeConfigs$ | async).inactiveAccountText }}"
		data-testid="registeredInactive"
	>
	</cdx-alert>
	<div class="additional-info-panel" *ngIf="(themeConfigs$ | async).name === 'cdx'">
		<div class="additional-info-title">
			<img class="alert-icon" src="./assets/alert.svg" />
			Use online shopping and automatically get an Everyday Rewards membership!
			<img (click)="toggleItem()" class="chevron" [ngStyle]="getChevronStyle()" src="./assets/chevron-down.svg" />
		</div>
		<div class="additional-info-content" [@expand]="panelStatus">
			<p class="additional-info-text">
				Your online shopping account has received an upgrade! When you shop online, you're now also getting the benefits
				from having an Everyday Rewards membership. <br />
				Not a member yet? Just sign up for online shopping and an Everyday Rewards account will be created for you, with
				the same login details. Enjoy:
			</p>
			<div><img class="large-image" src="./assets/savings.svg" />Collecting points on every shop</div>
			<div><img class="center-image" src="./assets/Shape-copy-2.svg" />Member prices on 1000+ products</div>
			<div><img src="./assets/e-voucher.svg" />Earning your $15 voucher</div>
			<div class="learn-more-text">
				<a target="_blank" [href]="(themeConfigs$ | async).learnMoreLink">Learn more here</a>
			</div>
		</div>
	</div>
	<form (ngSubmit)="onSubmit()" [formGroup]="form" class="form-content">
		<div>
			<fieldset
				cdxFieldset
				class="email-input-fieldset"
				[divider]="false"
				hideLegend="true"
				[legend]="'Enter your email'"
				[i18n]="'@@register-enterEmailLabel'"
			>
				<form-input
					autocomplete="email"
					class="email-input-form"
					formControlName="emailInput"
					size="full-width"
					#emailInput
					data-cy="'email'"
					inputType="email"
					data-testid="email"
					placeholder="Please enter email address"
				>
					<label for="emailInput" label>Your email <span class="asterisk">*</span></label>
				</form-input>
				<input type="password" [hidden]="true" />
				<form-input-error validation control="emailInput" data-cy="'email_err'">
					<p
						class="invalid-email-message"
						[cdxInvalidType]="'*'"
						i18n="@@register-invalidEmail"
						data-testid="emailError"
					>
						Please enter a valid email address
					</p>
				</form-input-error>
			</fieldset>
		</div>
		<div class="form-actions">
			<button cdxButton class="submit-button" value="Submit" tabindex="0" data-cy="'submit'" data-testid="submitButton">
				{{ submitButtonValue }}
				<svg
					width="16"
					height="13"
					viewBox="3 -5 32 32"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
					class="button-svg"
					*ngIf="(themeConfigs$ | async).name === 'edr'"
				>
					<path
						fill-rule="evenodd"
						d="M10.0011 32C9.54109 32 9.08112 31.84 8.70114 31.52C7.86117 30.8 7.76114 29.5401 8.48111 28.7001L19.3607 16.0006L8.48111 
						3.30103C7.76114 2.46106 7.86117 1.2011 8.70114 0.481126C9.54111 -0.238848 10.8011 -0.138852 11.521 0.701118L23.5206 14.7006C24.1606 
						15.4506 24.1606 16.5506 23.5206 17.3005L11.521 31.3C11.1211 31.77 10.5611 32 10.0011 32Z"
					/>
				</svg>
			</button>
		</div>
	</form>
</section>
