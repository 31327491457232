<form-dropdown
	class="theme-selector"
	name="theme"
	labelText="Theme:"
	[list]="themeArr"
	(onSelect)="changeTheme($event)"
	[selectedValue]=""
	*ngIf="showThemeSelector"
></form-dropdown>

<section class="container" *ngIf="!themeLoading" [ngClass]="{ themeLoaded: !themeLoading }">
	<div class="main" [hidden]="loading && this.currentURL === this.redirectingURL">
		<cdx-svg-definitions></cdx-svg-definitions>
		<registration-header *ngIf="!hideHeaderAndFooter"></registration-header>
		<div [@routeAnimations]="prepareRoute(outlet)">
			<router-outlet #outlet="outlet"></router-outlet>
		</div>
		<div class="loadding-wraper" *ngIf="loading">
			<cdx-wapple-loading class="registration-loading"></cdx-wapple-loading>
		</div>
	</div>
	<registration-edr-footer
		*ngIf="(themeConfigs$ | async).name === 'edr' && !hideHeaderAndFooter"
	></registration-edr-footer>
	<registration-ww-footer *ngIf="isOIDCEnabled && (themeConfigs$ | async).name === 'cdx'"></registration-ww-footer>
</section>

<cdx-modal-view></cdx-modal-view>
