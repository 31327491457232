import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService, AppSettings, BreakPointService } from '@woolworthsnz/styleguide';
import { slideInAnimation } from '../app.animations';
import { isMobileWebview, useOIDCTheme } from '../utils';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'registration-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	providers: [ApiService, AppSettings],
	animations: [slideInAnimation],
})
export class HeaderComponent implements OnInit {
	showBackButton: boolean;
	hideBackButton$: boolean;
	isOIDCEnabled: boolean;
	constructor(
		public location: Location,
		public breakpointService: BreakPointService,
		public router: Router,
		public route: ActivatedRoute
	) {
		while (route?.firstChild) {
			route = route.firstChild;
		}
		if ('showBackButton' in route?.snapshot.data) {
			this.showBackButton = route?.snapshot.data['showBackButton'];
		} else {
			this.showBackButton = true;
		}
	}

	ngOnInit(): void {
		this.isOIDCEnabled = useOIDCTheme();
	}

	hideBackbutton = () => {
		const path = this.location.path();
		if (path.indexOf('enter-email') > -1 && !this.isOIDCEnabled) {
			return true;
		}
		if (isMobileWebview() && path.indexOf('enter-email') > -1) {
			return true;
		}
		return false;
	};

	back() {
		if (this.location.path().indexOf('enter-email') > -1) {
			this.location.historyGo(-2);
		} else {
			this.location.back();
		}
	}
}
