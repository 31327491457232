/* eslint-disable max-len */
import { THEME_CONSTANTS } from '@woolworthsnz/styleguide';
import { environment } from '../environments/environment';
import { APP_ROUTES } from '../themes/app.routes';
export class AppSettings {
	static envVars = environment;
	static constants = THEME_CONSTANTS;
	static styleGuideConfig = {
		minimumAge: 13,
		password: {
			minLength: 8,
			requiredCharGroups: 2,
		},
	};

	// For changing HTML based on theme
	static themesConfig = {
		theme: {
			edr: {
				name: 'edr',
				enterEmailText: 'Do you have a Woolworths account? Sign in using the same email address and password.',
				inactiveAccountText: 'Contact our customer care team on <b>0800 969 337.</b>',
				createPasswordText:
					'Creating an Everyday Rewards account will also create a Woolworths account for you, using the same sign-in details.',
				resetPasswordText: 'Resetting your password here will also update your Woolworths account password.',
				serverErrorTitle: 'Sorry, our system is acting up',
				serverErrorText:
					"We've had a technical snag here. Try again in a bit or contact our customer care team on <b>0800 969 337.</b>",
				learnMoreLink: APP_ROUTES.edr.Help,
				termsAndConditionsLink: APP_ROUTES.edr.TermsAndConditions,
				privacyLink: APP_ROUTES.edr.Privacy,
			},
			cdx: {
				name: 'cdx',
				enterEmailText: 'Do you have an Everyday Rewards account? Sign in using the same email address and password.',
				inactiveAccountText: 'Contact our customer care team on <b>0800 40 40 40.</b>',
				createPasswordText:
					'Creating a Woolworths account will also create an Everyday Rewards account for you, using the same sign-in details.',
				resetPasswordText: 'Resetting your password here will also update your Everyday Rewards account password.',
				serverErrorTitle: 'Sorry, our system is acting up',
				serverErrorText:
					"We've had a technical snag here. Try again in a bit or contact our customer care team on <b>0800 40 40 40.</b>",
				learnMoreLink: APP_ROUTES.cdx.FAQs,
				termsAndConditionsLink: APP_ROUTES.cdx.TermsAndConditions,
				privacyLink: APP_ROUTES.cdx.Privacy,
			},
		},
	};

	static sessionInfoType = {
		ScanAndGoAndroid: 'Mobile',
		ScanAndGoIOS: 'Mobile',
		Trader: 'Browser',
		SSU: 'Browser',
		SimulateMobile: 'Mobile',
	};

	static oidcThemeClients = ['Trader', 'SSU'];

	static showForceUpdateClients = ['ScanAndGoAndroid', 'ScanAndGoIOS'];

	static apiConfigs = {
		'WoolworthsNZ-Request-System-Id': 'RegistrationUI',
		'WoolworthsNZ-Request-User-Id': 'RegistrationUI',
	};
	static addressFinder = {
		url: 'https://api.addressfinder.io/assets/v3/widget.js',
		key: 'PB934YGQDTLE6MAUFR8K',
	};

	static emailInactive = 'We can’t continue with your email';
	static emailInvalid = 'The email address entered is not valid. Please try again.';
	static captchaTokenExpired = 'We’re sorry. We seem to be having a technical problem. Please try again shortly.';
	static captchaBotMsg = 'We’re sorry. We were unable to verify your credentials. Please try again later.';
	static passwordNotMatchTitle = 'Incorrect password';
	static passwordNotMatch = 'Oops! Your password doesn’t match your account. Please try again.';
	static createActivationErrorMsg = 'These details don’t match our records, please try again.';
	static verifyMagicLinkFailed = `We’re sorry. The link in your email has either expired or is invalid. Please click on the continue button and try again.`;
	static defaultErrorMsg = `We seem to be having a technical problem. Please try again shortly.
	 If you continue to receive these errors, please contact our Customer Service team on <b>0800 40 40 40.</b>`;
	static defaultErrorTitle = 'Sorry we got into a problem';
	static resetPasswordFailed =
		'We hit a snag with our server while trying to reset your password. Try again in a bit or contact our customer care team on <b>0800 969 337.</b>';
	static resetPasswordTokenInvalid = 'Oops! Your reset password link has expired.';
	static resetPasswordTokenInvalidTitle = 'Reset password link expired';
	static breakpoints = THEME_CONSTANTS.breakpoints;
	static edrEndpoint = {
		DEV: {
			login:
				'https://bff-dev.everydayrewards.co.nz/api/v1/Auth/login?returnUrl=https%3A%2F%2Fwww-dev.everydayrewards.co.nz%2F%23sign-in-register',
		},
		CI: {
			login:
				'https://bff-dev.everydayrewards.co.nz/api/v1/Auth/login?returnUrl=https%3A%2F%2Fwww-dev.everydayrewards.co.nz%2F%23sign-in-register',
		},
		QA: {
			login:
				'https://bff-test.everydayrewards.co.nz/bff/api/v1/Auth/login?returnUrl=https%3A%2F%2Fwww-test.everydayrewards.co.nz%2F',
		},
		UAT: {
			login:
				'https://bff-uat.everydayrewards.co.nz/bff/api/v1/Auth/login?returnUrl=https%3A%2F%2Fwww-uat.everydayrewards.co.nz%2F',
		},
		PROD: {
			login:
				'https://bff.everydayrewards.co.nz/bff/api/v1/Auth/login?returnUrl=https%3A%2F%2Fwww.everydayrewards.co.nz%2F',
		},
	};
	static endpoints = {
		// Misc
		address: 'addresses',
		logout: 'shopper-logouts',
		productDetails: 'products',
		search: 'products',
		session: 'session',
		shell: 'shell',

		// Products
		productSearchSuggestions: 'products/search-suggestions',

		// Recipes
		recipes: 'recipes',
		recipeSearchSuggestions: 'recipes/search-suggestions',

		// Dynamic content
		dynamicContent: 'dynamic-content',
	};
}
