import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'a[click]:not([href]):not([role]):not([tabindex])'
})
export class TabbingClickDirective {
	@HostBinding('attr.role') role = 'button';
	@HostBinding('attr.tabindex') tabindex = '0';
	@HostListener('keydown', ['$event'])
	onKeyDown(e: any) {
		if (e.which === 13 || e.which === 32) {
			e.preventDefault();
			e.target.click();
		}
	}
}