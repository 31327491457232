import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FooterItems } from './ww-footer.data';

@Component({
	selector: 'registration-ww-footer',
	templateUrl: './ww-footer.component.html',
	styleUrls: ['./ww-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WWFooterComponent {
	public currentYear = new Date().getFullYear();
	navItems = FooterItems;
}
