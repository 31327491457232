<footer>
	<div class="footer__nav">
		<div class="footer__nav-container">
			<p>© {{ currentYear }} Everyday Rewards. All rights reserved.</p>
			<nav>
				<ul>
					<li *ngFor="let navItem of navItems">
						<a [href]="navItem.url">{{ navItem.label }}</a>
					</li>
				</ul>
			</nav>
		</div>
	</div>
</footer>
