<section class="main-section">
	<h1>Create your password</h1>
	<p>
		{{ (themeConfigs$ | async).createPasswordText }}
		<a class="link-text-registration" target="_blank" [href]="(themeConfigs$ | async).learnMoreLink">Learn more</a>
	</p>
	<form class="form-content" (ngSubmit)="onSubmit()" [formGroup]="form" data-testid="passwordForm">
		<div>
			<fieldset
				cdxFieldset
				[divider]="false"
				hideLegend="true"
				[legend]="'create password'"
				[i18n]="'@@register-createPasswordLabel'"
			>
				<label label>Password <span class="asterisk">*</span></label>
				<form-password-input
					[showPasswordHelpText]="false"
					data-cy="'password'"
					class="create-password"
					[showInputError]="false"
					passwordrules="minlength: 8; required: lower; required: upper; required: digit; required: [ !&quot;#$%&'()*+,./:;<=>?@\^_`{|}~];"
				>
				</form-password-input>
				<div class="password-requirement">
					<div>
						<cdx-svg-icon
							name="cross"
							size="small"
							fill="dark"
							display="inline"
							*ngIf="passwordMinimumLength; else requirementPassed"
							data-testid="password-requirement-notMetLength"
						></cdx-svg-icon>
					</div>
					<span
						[ngClass]="{
							'password-validation': passwordMinimumLength,
							'password-validation-green': !passwordMinimumLength
						}"
						i18n="@@passwordHelpText--minLength"
						>Password must contain at least 8 characters</span
					>
				</div>
				<div class="password-requirement">
					<div>
						<cdx-svg-icon
							name="cross"
							size="small"
							fill="dark"
							display="inline"
							*ngIf="passwordCharMismatch; else requirementPassed"
							data-testid="passwordRequirementNotMetChar"
						></cdx-svg-icon>
					</div>
					<span class="password-validation"
						>Include at least two of the following:
						<p class="password-validation-margin">
							<span
								[ngClass]="{
									'password-validation-small': !containsUpperCase,
									'password-validation-small-green': containsUpperCase
								}"
								i18n="@@passwordHelpText--upperLower"
								>Upper case</span
							>
							|
							<span
								[ngClass]="{
									'password-validation-small': !containsLowercase,
									'password-validation-small-green': containsLowercase
								}"
								i18n="@@passwordHelpText--upperLower"
								>Lower case</span
							>
							|
							<span
								[ngClass]="{
									'password-validation-small': !containsNumber,
									'password-validation-small-green': containsNumber
								}"
								i18n="@@passwordHelpText--number"
								>A number</span
							>
							|
							<span
								[ngClass]="{
									'password-validation-small': !containsSpecialCharacter,
									'password-validation-small-green': containsSpecialCharacter
								}"
								i18n="@@passwordHelpText--special"
								>A special character</span
							>
						</p>
					</span>
				</div>
				<ng-template #requirementPassed>
					<cdx-svg-icon
						class="success-green"
						name="tick"
						size="small"
						fill="currentColor"
						display="inline"
						data-testid="passwordRequirementMet"
					></cdx-svg-icon>
				</ng-template>
			</fieldset>
		</div>
		<div class="form-actions">
			<button
				cdxButton
				class="submit-button"
				value="Submit"
				tabindex="0"
				data-cy="'submit'"
				data-testid="submitButton"
				[disabled]="!form.valid"
			>
				{{ submitButtonValue }}
				<svg
					width="16"
					height="13"
					viewBox="3 -4 32 32"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
					class="button-svg"
					*ngIf="(themeConfigs$ | async).name === 'edr'"
				>
					<path
						fill-rule="evenodd"
						d="M10.0011 32C9.54109 32 9.08112 31.84 8.70114 31.52C7.86117 30.8 7.76114 29.5401 8.48111 28.7001L19.3607 16.0006L8.48111 3.30103C7.76114 2.46106 7.86117
					  1.2011 8.70114 0.481126C9.54111 -0.238848 10.8011 -0.138852 11.521 0.701118L23.5206 14.7006C24.1606
					  15.4506 24.1606 16.5506 23.5206 17.3005L11.521 31.3C11.1211 31.77 10.5611 32 10.0011 32Z"
					/>
				</svg>
			</button>
		</div>
	</form>
</section>
