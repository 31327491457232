<section class="main-section reset-password-section">
	<h1>Reset Password</h1>
	<div>
		<p>Your password has been changed successfully. Log in with your new password.</p>
	</div>
	<form (ngSubmit)="onSubmit()" *ngIf="showButton">
		<div class="form-actions">
			<button
				cdxButton
				class="submit-button"
				*ngIf="showButton"
				value="Submit"
				tabindex="0"
				data-cy="'submit'"
				data-testid="submitButton"
			>
				{{ submitButtonValue }}
				<svg
					width="16"
					height="13"
					viewBox="3 -5 32 32"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
					class="button-svg"
					*ngIf="(themeConfigs$ | async).name === 'edr'"
				>
					<path
						fill-rule="evenodd"
						d="M10.0011 32C9.54109 32 9.08112 31.84 8.70114 31.52C7.86117 30.8 7.76114 29.5401 8.48111 28.7001L19.3607 16.0006L8.48111 
						3.30103C7.76114 2.46106 7.86117 1.2011 8.70114 0.481126C9.54111 -0.238848 10.8011 -0.138852 11.521 0.701118L23.5206 14.7006C24.1606 
						15.4506 24.1606 16.5506 23.5206 17.3005L11.521 31.3C11.1211 31.77 10.5611 32 10.0011 32Z"
					/>
				</svg>
			</button>
		</div>
	</form>
</section>
