import { Injectable, InjectionToken } from '@angular/core';
@Injectable({
	providedIn: 'root',
})
export class EnvService {
	constructor() {}
	public registationAPI = 'customer-registration.cdx.nz';
	public accountExistEndpoint = '/api/v1/registrations/available';
	public setConsentEndpoint = '/api/v1/registrations/consent';
	public passwordless = '/api/v1/account/passwordless_login';
	public passwordlessToken = '/api/v1/account/passwordless_login/token';
	public login = '/api/v1/registrations/password';
	public registerCustomer = '/api/v1/registrations';
	public loginEDR = '/api/v1/account/password_login';
	public create = '/api/v1/account/create_registration';
	public sendForgotPasswordEndpoint = '/api/v1/account/forgot_password';
	public updatePasswordEndpoint = '/api/v1/account/update_password';
	public registerEDRCustomer = '/api/v1/account/create_registration';
	public profile = '/api/v1/profile';
	public selfServiceURL = 'https://account.ci.countdown.co.nz/account';
	public shopURL = 'https://www.countdown.co.nz/';
	public edrURL = 'https://www.everydayrewards.co.nz/';
	public reCaptchaKey = '';
	public appInsightInstructmentKey = '';
	public environment = 'PROD';
	update = () => globalThis.__env;
}

export const envServiceFactory = () => {
	const env = new EnvService();

	const browserWindowEnv = window['__env'];
	globalThis.__env = Object.assign({}, env, browserWindowEnv);
	return globalThis.__env;
};

export const ENV_SERVICE = new InjectionToken<any>('env', {
	providedIn: 'root',
	factory: envServiceFactory,
});

export const EnvServiceProvider = {
	provide: EnvService,
	useFactory: envServiceFactory,
	deps: [],
};
