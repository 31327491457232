import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FooterItems } from './edr-footer.data';

@Component({
	selector: 'registration-edr-footer',
	templateUrl: './edr-footer.component.html',
	styleUrls: ['./edr-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EDRFooterComponent {
	public currentYear = new Date().getFullYear();
	navItems = FooterItems;
}
