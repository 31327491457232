import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { AppSettings } from 'apps/edr/src/app/app.settings';
import { BehaviorSubject } from 'rxjs';
@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	private style: HTMLLinkElement;
	private cssFile: string;
	private themeCSSID = 'cdx';
	public currentTheme = 'cdx';
	// set defaul theme configs
	configs: BehaviorSubject<any> = new BehaviorSubject(<any>AppSettings.themesConfig.theme['cdx']);
	themeLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(@Inject(DOCUMENT) private document: Document) {}

	setTheme(theme: string, renderer2: Renderer2) {
		this.themeLoading.next(true);
		this.cssFile = `${theme}.css`;
		this.currentTheme = theme;
		this.removeExistingThemeStyle(renderer2, this.themeCSSID);

		// Create a link element via Angular's renderer to avoid SSR troubles
		this.style = renderer2.createElement('link') as HTMLLinkElement;

		// Set type of the link item and path to the css file
		renderer2.setProperty(this.style, 'rel', 'stylesheet');
		renderer2.setProperty(this.style, 'href', this.cssFile);
		renderer2.setProperty(this.style, 'id', this.themeCSSID);

		// Set favicon dynamically
		let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
		if (!link) {
			link = document.createElement('link');
			link.rel = 'icon';
			document.head.appendChild(link);
		}
		link.href = `assets/${theme}.ico`;

		// Add the style to the head section
		renderer2.appendChild(this.document.head, this.style);

		// add some sample config here
		const exampleConfigs = AppSettings.themesConfig.theme[theme];
		this.configs.next(exampleConfigs);

		// add some delay to avoid flickering

		setTimeout(() => {
			this.themeLoading.next(false);
		}, 700);
	}

	removeExistingThemeStyle(renderer2: Renderer2, themeCSSID: string) {
		const themeIDHTMlElem = this.document.getElementById(themeCSSID);
		if (themeIDHTMlElem) {
			renderer2.removeChild(this.document.head, themeIDHTMlElem);
		}
	}
}
