import { Inject, Injectable } from '@angular/core';
import { map, tap, catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppService } from './app.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as userActions from './app.actions';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {
	Login,
	LoginMagicLink,
	Sessions,
	VerifyMagicLink,
	RegisterCustomer,
	JsBridgeCookieInfo,
	GetCustomerProfile,
	GetCustomerProfileResponse,
} from '../utils/type/registration.type';
import { of } from 'rxjs';
import { sessionSelector } from './app.selectors';
import { Store } from '@ngrx/store';
import { AppSettings } from '../app.settings';
import { AppInsightLoggingService } from '../utils/services/appInsightLogging.service';
import { EnvService } from '../env.service';
import { SendForgotPassword, UpdatePassword } from './app.state';
import { TealiumUtagService } from '@woolworthsnz/analytics';

declare const window: any;
globalThis.captchaErrorCount = 0;
globalThis.missingActiveRetry = 0;
@Injectable()
export class AppEffects {
	constructor(
		private actions$: Actions,
		private appService: AppService,
		private router: Router,
		public location: Location,
		private readonly store: Store,
		private appInsight: AppInsightLoggingService,
		private tealiumService: TealiumUtagService,
		@Inject(EnvService) private _env: EnvService
	) {}

	ifRegistered$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.checkIfRegistered),
			switchMap((action) =>
				this.appService.checkIfRegistered(action.email).pipe(
					map((response) => {
						this.router.navigateByUrl(!response.isAvailable ? '/enter-password' : '/create-password');
						return userActions.setRegistered({ isAvailable: response.isAvailable });
					}),
					catchError((err) => {
						if (this.hasCaptchaError(err)) {
							return this.returnCaptchaError(err, userActions.checkIfRegisteredFailed);
						} else if (this.has400sError(err)) {
							return of(
								userActions.registeredInactive({
									msg: AppSettings.emailInactive,
								})
							);
						}
						return of(
							userActions.checkIfRegisteredFailed({
								msg: AppSettings.defaultErrorMsg,
							})
						);
					})
				)
			)
		)
	);

	login$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.login),
			switchMap((login: Login) =>
				this.appService.login(login).pipe(
					map((response) => userActions.loginSuccess(response)),
					catchError((err) => {
						if (this.hasCaptchaError(err)) {
							return this.returnCaptchaError(err, userActions.loginFailed);
						} else if (this.has400sError(err)) {
							return of(
								userActions.loginFailed({ title: AppSettings.passwordNotMatchTitle, msg: AppSettings.passwordNotMatch })
							);
						}
						return of(userActions.loginServerError({ msg: AppSettings.defaultErrorMsg }));
					})
				)
			)
		)
	);

	loginSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.loginSuccess),
			withLatestFrom(this.store.select(sessionSelector)),
			map(([session]: any) => {
				let sessionData = session?.cookieInfo;
				if (!sessionData) {
					sessionData = sessionStorage.getItem('session-response');
				}
				const cookie: JsBridgeCookieInfo = {
					cookieInfo: sessionData,
				};
				this.setcookie(cookie);
				this.tealiumService.link({
					tealium_event: 'ssu_sign_in',
				});
				if (!globalThis.profileUpdateFormEnabled) {
					this.redirectToProxy(session?.cookieInfo);
					return userActions.redirectedToProxy();
				} else {
					return userActions.getCustomerProfile({ token: session.token });
				}
			})
		)
	);

	registerCustomerSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.registerCustomerSuccess),
			withLatestFrom(this.store.select(sessionSelector)),
			map(([session]: any) => {
				const cookie: JsBridgeCookieInfo = {
					cookieInfo: session.cookieInfo,
				};
				this.tealiumService.link({
					tealium_event: 'ssu_register',
				});
				this.setcookie(cookie);
				this.redirectToProxy();
				return userActions.redirectedToProxy();
			})
		)
	);

	create$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.create),
			map(() => userActions.createSuccess()),
			tap(() => {
				this.redirectTo('/register');
			})
			// No error state needed
		)
	);

	updatePassword$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.updatePassword),
			switchMap((updatePassword: UpdatePassword) =>
				this.appService.updatePassword(updatePassword).pipe(
					map(() => userActions.updatePasswordSuccess()),
					tap(() => {
						this.redirectTo('/account/reset-password/success');
					}),
					catchError((err) => {
						if (this.hasCaptchaError(err)) {
							return this.returnCaptchaError(err, userActions.updatePasswordFailed);
						} else if (this.has400sError(err)) {
							return of(
								userActions.updatePasswordExpired({
									msg: AppSettings.resetPasswordTokenInvalid,
								})
							);
						}
						return of(
							userActions.updatePasswordFailed({
								msg: AppSettings.defaultErrorMsg,
							})
						);
					})
				)
			)
		)
	);

	saveCustomerInfoForLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.saveCustomerInfoForLogin),
			withLatestFrom(this.store.select(sessionSelector)),
			switchMap(([saveCustomerInfoForLogin, session]) => {
				if (saveCustomerInfoForLogin.updateMode) {
					const registerCustomer = {
						firstName: saveCustomerInfoForLogin.customerInfo.firstName,
						lastName: saveCustomerInfoForLogin.customerInfo.lastName,
						dateOfBirth: saveCustomerInfoForLogin.customerInfo.dateOfBirth,
						mobilePhoneNumber:
							saveCustomerInfoForLogin.contactInfo.mobilePhoneCode + saveCustomerInfoForLogin.contactInfo.mobilePhone,
						edrSetting: saveCustomerInfoForLogin.preferences[0]?.edrSetting,
						countdownSetting: saveCustomerInfoForLogin.preferences[0]?.countdownSetting,
					};
					return this.appService.updateCustomer(registerCustomer, session.token).pipe(
						map(() => {
							this.redirectToProxy();
							return userActions.redirectedToProxy();
						}),
						catchError((err) => {
							if (this.hasCaptchaError(err)) {
								return this.returnCaptchaError(err, userActions.registerCustomerFailed);
							} else {
								return of(
									userActions.registerCustomerFailed({
										msg: err?.error?.error?.errors[0]?.message ?? AppSettings.defaultErrorMsg,
									})
								);
							}
						})
					);
				} else {
					const registerCustomer: RegisterCustomer = {
						email: saveCustomerInfoForLogin.email,
						password: saveCustomerInfoForLogin.password,
						sessionInfoType: saveCustomerInfoForLogin.sessionInfoType,
						customerProfileInfo: {
							firstName: saveCustomerInfoForLogin.customerInfo.firstName,
							lastName: saveCustomerInfoForLogin.customerInfo.lastName,
							dateOfBirth: saveCustomerInfoForLogin.customerInfo.dateOfBirth,
							mobilePhoneNumber:
								saveCustomerInfoForLogin.contactInfo.mobilePhoneCode + saveCustomerInfoForLogin.contactInfo.mobilePhone,
						},
						edrSetting: saveCustomerInfoForLogin.preferences.edrSetting,
						countdownSetting: saveCustomerInfoForLogin.preferences.countdownSetting,
					};
					return this.appService.registerCustomer(registerCustomer).pipe(
						map((response) => userActions.registerCustomerSuccess(response)),
						catchError((err) => {
							if (this.hasCaptchaError(err)) {
								return this.returnCaptchaError(err, userActions.registerCustomerFailed);
							} else {
								return of(
									userActions.registerCustomerFailed({
										msg: AppSettings.defaultErrorMsg,
									})
								);
							}
						})
					);
				}
			})
		)
	);

	getCustomerProfile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.getCustomerProfile),
			switchMap((getCustomerProfile: GetCustomerProfile) =>
				this.appService.getCustomerProfile(getCustomerProfile.token).pipe(
					map((response: GetCustomerProfileResponse) => {
						if (response.firstName && response.lastName && response.mobilePhoneNumber && response.dateOfBirth) {
							this.redirectToProxy();
							return userActions.redirectedToProxy();
						} else {
							this.redirectTo('/register?update=true');
							return userActions.saveCustomerInfo({
								customerInfo: {
									dateOfBirth: response?.dateOfBirth,
									firstName: response?.firstName,
									lastName: response?.lastName,
								},
								contactInfo: {
									emailAddress: response?.email,
									mobilePhone: response?.mobilePhoneNumber?.substring(3),
									mobilePhoneCode: response?.mobilePhoneNumber?.substring(0, 3),
									homePhone: response?.homePhoneNumber?.substring(2),
									homePhoneCode: response?.homePhoneNumber?.substring(0, 2),
								},
							});
						}
					})
				)
			)
		)
	);

	sendMagicLink$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.sendMagicLink),
			switchMap((loginMagiclink: LoginMagicLink) =>
				this.appService.sendMagicLink(loginMagiclink).pipe(
					map(() => {
						/* todo put into ngrx/router-store */
						this.router.navigateByUrl('account/check-email');
						return userActions.sendMagicLinkSuccess();
					}),
					catchError(() => of(userActions.sendMagicLinkFailed({ msg: AppSettings.defaultErrorMsg })))
				)
			)
		)
	);

	sendForgotPassword$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.sendForgotPassword),
			switchMap((sendForgotPassword: SendForgotPassword) =>
				this.appService.sendForgotPassword(sendForgotPassword).pipe(
					map(() => {
						this.router.navigateByUrl('account/reset-password/sent-email');
						return userActions.sendForgotPasswordSuccess();
					}),
					catchError(() => of(userActions.sendForgotPasswordFailed({ msg: AppSettings.defaultErrorMsg })))
				)
			)
		)
	);

	// same as send magic link except redirects to /enter-email
	resendMagicLink$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.resendMagicLink),
			switchMap((loginMagiclink: LoginMagicLink) =>
				this.appService.sendMagicLink(loginMagiclink).pipe(
					map(() => {
						/* todo put into ngrx/router-store */
						this.router.navigateByUrl('/enter-email');
						this.appInsight?.logMetric('UI-sendMagicLink', 1);
						return userActions.sendMagicLinkSuccess();
					}),
					catchError(() => of(userActions.sendMagicLinkFailed({ msg: AppSettings.defaultErrorMsg })))
				)
			)
		)
	);

	verifyMagicLink$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.verifyMagicLink),
			switchMap((verifyMagicLink: VerifyMagicLink) =>
				this.appService.verifyMagicLink(verifyMagicLink).pipe(
					map((response: Sessions) => {
						if (response.state) {
							if (response.state.indexOf('gig_oidcContext') > -1) {
								sessionStorage.setItem('gigyaCache', response.state);
							}
						}
						return userActions.verifyMagicLinkSuccess(response);
					}),
					catchError(() => {
						this.appInsight?.logMetric('UI-verifyMagicLinkFailed', 1);
						this.router.navigateByUrl('/account/magic-link-expired');
						return of(userActions.verifyMagicLinkFailed({ msg: AppSettings.verifyMagicLinkFailed }));
					})
				)
			)
		)
	);

	verifyMagicLinkSuccess$ = createEffect(() => {
		this.appInsight?.logMetric('UI-verifyMagicLinkSuccess', 1);
		return this.actions$.pipe(
			ofType(userActions.verifyMagicLinkSuccess),
			withLatestFrom(this.store.select(sessionSelector)),
			map(([session]: any) => {
				const cookie: JsBridgeCookieInfo = {
					cookieInfo: session.cookieInfo,
				};
				this.setcookie(cookie);
				this.redirectToProxy();
				return userActions.redirectedToProxy();
			})
		);
	});

	setcookie = (cookie: JsBridgeCookieInfo) => {
		let expires = '';
		const date = new Date();
		date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
		expires = 'expires=' + date.toUTCString();
		const cookieValue = cookie?.cookieInfo?.cookieValue;
		if (!cookie?.cookieInfo || cookieValue?.length < 100) {
			this.appInsight?.logException(new Error('edr-empty-cookie'));
			this.appInsight?.logEvent('edr-empty-cookie');
		}
		document.cookie = `${cookie?.cookieInfo?.cookieName}=${encodeURIComponent(
			cookieValue
		)}; ${expires}; path=/; SameSite=None; Secure; Priority=high`;
	};

	redirectToProxy = (cookieValue?: string) => {
		// onLogin callback in index.html,  will handle redirect to proxy directly, in somecase rarecase
		// Gigya didnt trigger auto redirect,  ssu will redirect user to  proxy and handle error there (known as proxy timeout error)
		window?.gigya?.accounts?.session?.verify({
			callback: function (res) {
				if (res?.errorCode !== 0) {
					this.appInsight?.logEvent('gigya-session-false', cookieValue);
				} else {
					this.appInsight?.logEvent('gigya-session-true');
				}
			}.bind(this),
		});

		// if didnt trigger auto redirect, redirect suer to proxy anyway, proxy will handle error therer
		setTimeout(() => {
			window.location.href = `/auth/proxy.html?mode=afterLogin`;
		}, 3000);
	};

	redirectTo = (url: string) => {
		setTimeout(() => {
			this.router.navigateByUrl(url);
		}, 1000);
	};

	captchaAsBot = (response: any) => {
		if (response?.error?.error?.errors[0]?.message.indexOf('CAPTCHA validation failed') > -1) {
			return true;
		}
		return false;
	};

	capchaTokenExpired = (response: any) => {
		if (response?.error?.error?.errors[0]?.message.indexOf('CAPTCHA expired') > -1) {
			return true;
		}
		return false;
	};

	returnCaptchaError = (err, cb: Function) => {
		if (this.captchaAsBot(err)) {
			return of(cb({ msg: AppSettings.captchaBotMsg }));
		} else if (this.capchaTokenExpired(err)) {
			globalThis.captchaErrorCount++;
			return of(cb({ msg: AppSettings.captchaTokenExpired }));
		} else {
			return of(cb({ msg: AppSettings.defaultErrorMsg }));
		}
	};

	hasCaptchaError = (response: any): boolean => {
		if (response?.status === 422 || response?.error?.error?.code === 422) {
			this.appInsight.logEvent(response?.error?.error?.errors[0]?.message);
			return true;
		}
		return false;
	};

	has400sError = (response: any) => {
		if (
			(response?.status >= 400 && response?.status < 500) ||
			(response?.error?.error?.code >= 400 && response?.error?.error?.code < 500)
		) {
			return true;
		}
		return false;
	};

	has500sError = (response: any) => {
		if (response?.status >= 500 || response?.error?.error?.code >= 500) {
			return true;
		}
		return false;
	};
}
