import { Component, ChangeDetectionStrategy, HostBinding, Input, OnInit } from '@angular/core';

@Component({
	selector: 'form-fieldset, [cdxFieldset]',
	template: `
		<legend [class.sr-only]="hideLegend" i18n="i18n">{{ legend }}</legend>
		<ng-content></ng-content>
	`,
	styleUrls: ['./fieldset.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldsetComponent implements OnInit {
	@HostBinding('attr.data-layout')
	@Input()
	layout: 'narrow' | 'medium' | 'large' = 'large';

	@HostBinding('class.fieldset--withDivider')
	@Input()
	divider = false;

	@Input() hideLegend = false;
	@Input() i18n: string;
	@Input() legend: string;
	constructor() {}

	ngOnInit() {
		if (!this.i18n || !this.legend) {
			console.error('legend and internatinalisation are mandatory for filedset');
		}
	}
}
