<section class="main-section">
	<h1>Sign in</h1>
	<cdx-alert
		[type]="'success'"
		class="success-alert"
		title="Nice! We’ve found your account"
		description="Enter your existing Woolworths account password to sign in."
		icon="round-checked-filled"
		[candismiss]="true"
		*ngIf="showAlert"
		data-testid="successAlert"
	>
	</cdx-alert>
	<cdx-alert
		[type]="'error'"
		class="error-alert"
		title="{{ loginFailedTitle$ | async }}"
		[description]="(appstate$ | async).loginFailed"
		*ngIf="(appstate$ | async).loginFailed"
		data-testid="incorrectPasswordAlert"
	>
	</cdx-alert>
	<cdx-alert
		[type]="'error'"
		class="error-alert"
		title="{{ (themeConfigs$ | async).serverErrorTitle }}"
		description="{{ (themeConfigs$ | async).serverErrorText }}"
		*ngIf="(appstate$ | async).loginServerError"
		data-testid="serverErrorAlert"
	>
	</cdx-alert>
	<cdx-alert
		[type]="'error'"
		class="error-alert"
		title="{{ (themeConfigs$ | async).serverErrorTitle }}"
		description="{{ (themeConfigs$ | async).serverErrorText }}"
		*ngIf="(appstate$ | async).sendMagicLinkFailed"
		data-testid="magicLinkErrorAlert"
	>
	</cdx-alert>

	<form class="form-content" (ngSubmit)="onSubmit()" [formGroup]="form">
		<div>
			<fieldset
				cdxFieldset
				[divider]="false"
				hideLegend="true"
				[legend]="'Enter your password'"
				[i18n]="'@@register-enterPasswordLabel'"
				class="enter-password"
			>
				<label label>Password <span class="asterisk">*</span></label>
				<form-password-input
					[showPasswordHelpText]="false"
					data-testid="'password'"
					[validationMessage]="'Please enter a valid password'"
					autocomplete="current-password"
					class="enter-password"
				>
				</form-password-input>
			</fieldset>

			<h4>Forgot your password?</h4>
			<div class="forgot-password-options">
				<div *ngIf="(themeConfigs$ | async).name === 'edr'">
					<p class="password-free-login" *ngIf="!hideMagicLink">
						Email me a
						<a class="link-text-registration" (click)="sendMagicLink(); hideAlert()" data-testid="sendMagicLink"
							>password-free link</a
						>
						to sign in
					</p>
					<a class="link-text-registration" (click)="resetPassword()" data-testid="resetPassword">Reset my password</a>
				</div>
				<div *ngIf="(themeConfigs$ | async).name === 'cdx'">
					<p class="password-free-login" *ngIf="!hideMagicLink">
						<a class="link-text-registration-green" (click)="sendMagicLink(); hideAlert()" data-testid="sendMagicLink"
							>Email me a password-free link to sign in</a
						>
					</p>
					<a class="link-text-registration-green" (click)="resetPassword()" data-testid="resetPassword"
						>Reset my password</a
					>
				</div>

				<p class="terms-and-conditions">
					<ng-container
						*ngIf="(themeConfigs$ | async).name === 'edr'; then edrTermsAndConditions; else cdxTermsAndConditions"
					></ng-container>
					<ng-template #edrTermsAndConditions>
						<p class="terms-and-conditions-text">
							I've read and accepted
							<a class="link-text-registration" target="_blank" [href]="edrTermsAndConditionsLink"
								>Everyday Rewards T&Cs</a
							>,
							<a class="link-text-registration" target="_blank" [href]="cdxTermsAndConditionsLink"
								>Woolworths NZ Online Shopping T&Cs</a
							>, and
							<a class="link-text-registration" target="_blank" [href]="cdxPrivacyCentre"
								>Woolworths NZ Privacy Policy</a
							>.
						</p>
					</ng-template>
					<ng-template #cdxTermsAndConditions>
						<p class="terms-and-conditions-text">
							I've read and accepted
							<a class="link-text-registration" target="_blank" [href]="cdxTermsAndConditionsLink"
								>Woolworths NZ Online Shopping T&Cs</a
							>,
							<a class="link-text-registration" target="_blank" [href]="edrTermsAndConditionsLink"
								>Everyday Rewards T&Cs</a
							>
							and the
							<a class="link-text-registration" target="_blank" [href]="cdxPrivacyCentre"
								>Woolworths NZ Privacy Policy</a
							>.
						</p>
					</ng-template>
				</p>
			</div>
		</div>
		<div class="form-actions">
			<button
				cdxButton
				class="submit-button"
				value="Submit"
				tabindex="0"
				data-cy="'submit'"
				data-testid="submitButton"
				(click)="hideAlert()"
			>
				{{ submitButtonValue }}
				<svg
					width="16"
					height="13"
					viewBox="3 -5 32 32"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
					class="button-svg"
					*ngIf="(themeConfigs$ | async).name === 'edr'"
				>
					<path
						fill-rule="evenodd"
						d="M10.0011 32C9.54109 32 9.08112 31.84 8.70114 31.52C7.86117 30.8 7.76114 29.5401 8.48111 28.7001L19.3607 16.0006L8.48111
						3.30103C7.76114 2.46106 7.86117 1.2011 8.70114 0.481126C9.54111 -0.238848 10.8011 -0.138852 11.521 0.701118L23.5206 14.7006C24.1606
						15.4506 24.1606 16.5506 23.5206 17.3005L11.521 31.3C11.1211 31.77 10.5611 32 10.0011 32Z"
					/>
				</svg>
			</button>
		</div>
	</form>
</section>
