import { Component, Inject } from '@angular/core';
import { AppInsightLoggingService } from '../../utils/services/appInsightLogging.service';
import { clientId, getTheme, isMobileWebview } from '../../utils';
import { ThemeService } from '../../utils/services/themes.service';
import { EnvService } from '../../env.service';
declare const window: any;
@Component({
	selector: 'registration-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
	errorMsg: string;
	ref: string;
	hideRetry: boolean;

	constructor(
		private appInsight: AppInsightLoggingService,
		private themeService: ThemeService,
		@Inject(EnvService) private _env: EnvService
	) {
		const urlParams = new URLSearchParams(window.location.search);
		this.errorMsg = `${decodeURI(urlParams.get('message'))}`;
		this.ref = `${clientId() ?? getTheme() ?? 'web'}-${Math.floor(Math.random() * 90000) + 10000}`;
		const gigyaCache = sessionStorage.getItem('gigyaCache');
		this.hideRetry = isMobileWebview();

		if (this.errorMsg) {
			this.appInsight?.logEvent(`${this.errorMsg}`, {
				id: this.ref,
				cache: gigyaCache,
			});
		}
	}
	retry() {
		location.reload();
	}
}
