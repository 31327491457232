import { CurrencyPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutoFocusDirective, FormModule, FormService } from '@woolworthsnz/form';
import { ApiService, APP_SETTINGS, StyleguideModule, ANALYTICS_PROVIDER } from '@woolworthsnz/styleguide';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { AppSettings } from './app.settings';
import { AppRoutingModule } from './app-routing.module';
import { JsBridgeService } from './utils';

// ngrx related imports
import { StoreModule, ActionReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducer, sessionReducer, userReducer, encryptedReducer } from './state/app.reducer';
import { AppEffects } from './state/app.effects';
import { EffectsModule } from '@ngrx/effects';
import { storeLogger } from 'ngrx-store-logger';
import { localStorageSync } from 'ngrx-store-localstorage';

/* registration*/
import { CreatePasswordComponent } from './login/create-password/create-password.component';
import { EnterEmailComponent } from './login/enter-email/enter-email.component';
import { EnterPasswordComponent } from './login/enter-password/enter-password.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { ResetPasswordSentEmailComponent } from './account/reset-password-sent-email/reset-password-sent-email.component';
import { ResetPasswordSuccessComponent } from './account/reset-password-success/reset-password-success.component';
import { SetNewPasswordComponent } from './account/set-new-password/set-new-password.component';
import { HeaderComponent } from './header/header.component';
import { EDRFooterComponent } from './edr-footer/edr-footer.component';
import { AppComponent } from './app.component';
import { HttpRequestInterceptor } from './utils/loading.interceptor';
import { environment } from '../environments/environment';
import { CryptoService } from './utils/services/cryptoService.service';
import { EnvConfigComponent } from './env-config/env-config.component';
import { RegisterComponent } from './register/register.component';
import { RegisterAddressDetailsComponent } from './register/components/register-address-details/register-address-details.component';
import { RegisterShopperDetailsComponent } from './register/components/register-shopper-details/register-shopper-details.component';
import { ErrorHandlerService } from './utils/services/errorHandler.service';
import { ErrorComponent } from './account/error/error.component';
import { UpdateComponent } from './update/update.component';
import { MagicLinkExpiredComponent } from './login/magic-link-expired/magic-link-expired.component';
import { MagicLinkSentComponent } from './login/magic-link-sent/magic-link-sent.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { WWFooterComponent } from './ww-footer/ww-footer.component';

export const logger = (reducer: ActionReducer<any>) => storeLogger()(reducer);
export const localStorageSyncReducer = (reducer: ActionReducer<any>): ActionReducer<any> =>
	localStorageSync({ keys: ['encrypted'], rehydrate: true })(reducer);
export const sessiontorageSyncReducer = (reducer: ActionReducer<any>): ActionReducer<any> =>
	localStorageSync({ keys: ['user', 'app', 'session'], rehydrate: true, storage: sessionStorage })(reducer);
export const metaReducers = environment.production
	? [localStorageSyncReducer, sessiontorageSyncReducer]
	: [logger, localStorageSyncReducer, sessiontorageSyncReducer];

@NgModule({
	declarations: [
		AppComponent,
		CreatePasswordComponent,
		EnterEmailComponent,
		EnterPasswordComponent,
		ResetPasswordComponent,
		ResetPasswordSentEmailComponent,
		ResetPasswordSuccessComponent,
		SetNewPasswordComponent,
		HeaderComponent,
		EDRFooterComponent,
		WWFooterComponent,
		MagicLinkSentComponent,
		EnvConfigComponent,
		RegisterComponent,
		RegisterAddressDetailsComponent,
		RegisterShopperDetailsComponent,
		ErrorComponent,
		UpdateComponent,
		MagicLinkExpiredComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormModule,
		OverlayModule,
		HttpClientModule,
		ReactiveFormsModule,
		FormsModule,
		AppRoutingModule,
		StyleguideModule.forRoot(),
		StoreModule.forRoot(
			{ app: appReducer, session: sessionReducer, user: userReducer, encrypted: encryptedReducer },
			{ metaReducers }
		),
		StoreDevtoolsModule.instrument({
			name: 'customx-registration',
			maxAge: 25,
			logOnly: environment.production,
		connectInZone: true}),
		EffectsModule.forRoot([AppEffects]),
	],
	providers: [
		FormGroupDirective,
		AutoFocusDirective,
		ApiService,
		FormService,
		CurrencyPipe,
		JsBridgeService,
		CryptoService,
		DatePipe,
		{ provide: ErrorHandler, useClass: ErrorHandlerService },
		{ provide: APP_SETTINGS, useValue: AppSettings },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true,
		},
		{
			provide: ANALYTICS_PROVIDER,
			useExisting: TealiumUtagService,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
