<header class="header">
	<div class="back-section" *ngIf="showBackButton">
		<a class="back-components" (click)="back()" [hidden]="hideBackbutton()">
			<svg class="icon-left" width="8" height="16" viewBox="0 -1 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6.99995 16C7.22994 16 7.45993 15.92 7.64992 15.76C8.06991 15.4 8.11992 14.77 7.75993 14.3501L2.32012 8.00029L7.75993 1.65051C8.11992 
					1.23053 8.06991 0.60055 7.64992 0.240563C7.22994 -0.119424 6.59995 -0.0694259 6.23997 0.350559L0.240181 7.35031C-0.0798071 7.7253 -0.0798071 
					8.27528 0.240181 8.65026L6.23997 15.65C6.43996 15.885 6.71996 16 6.99995 16Z"
					fill="#3A474E"
				/>
			</svg>
			<span class="back-text">Back</span></a
		>
	</div>
	<div class="separator-div" *ngIf="showBackButton" [hidden]="hideBackbutton()">
		<hr class="separator" />
	</div>
	<nav class="nav">
		<div *ngIf="isOIDCEnabled" class="icon-logo"></div>
	</nav>
</header>
