import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnterEmailComponent } from './login/enter-email/enter-email.component';
import { EnterPasswordComponent } from './login/enter-password/enter-password.component';
import { CreatePasswordComponent } from './login/create-password/create-password.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { ResetPasswordSentEmailComponent } from './account/reset-password-sent-email/reset-password-sent-email.component';
import { ResetPasswordSuccessComponent } from './account/reset-password-success/reset-password-success.component';
import { SetNewPasswordComponent } from './account/set-new-password/set-new-password.component';
import { MagicLinkSentComponent } from './login/magic-link-sent/magic-link-sent.component';
import { EmailGuard, ProdGuard } from './state/app.guards';
import { EnvConfigComponent } from './env-config/env-config.component';
import { RegisterComponent } from './register/register.component';
import { ErrorComponent } from './account/error/error.component';
import { UpdateComponent } from './update/update.component';
import { MagicLinkExpiredComponent } from './login/magic-link-expired/magic-link-expired.component';

export const routes: Routes = [
	{
		path: 'enter-email',
		component: EnterEmailComponent,
		data: { title: 'Enter your email address | Login', animation: 'EnterEmail' },
	},
	{
		path: 'enter-password',
		component: EnterPasswordComponent,
		canActivate: [EmailGuard],
		data: { title: 'Enter your password | Login', animation: 'EnterPassword' },
	},
	{
		path: 'create-password',
		component: CreatePasswordComponent,
		canActivate: [EmailGuard],
		data: { title: 'Create new account password | Login', animation: 'CreatePassword' },
	},
	{
		path: 'account/reset-password',
		component: ResetPasswordComponent,
		canActivate: [],
		data: { title: 'Reset your password', animation: 'ResetPassword', showBackButton: false },
	},
	{
		path: 'account/reset-password/sent-email',
		component: ResetPasswordSentEmailComponent,
		canActivate: [],
		data: { title: 'Reset password email sent', animation: 'ResetPasswordSentEmail', showBackButton: false },
	},
	{
		path: 'account/reset-password/success',
		component: ResetPasswordSuccessComponent,
		canActivate: [],
		data: { title: 'Reset your password', animation: 'ResetPasswordSuccess', showBackButton: false },
	},
	{
		path: 'account/reset-password/set-password',
		component: SetNewPasswordComponent,
		canActivate: [],
		data: { title: 'Set new password', animation: 'SetNewPassword', showBackButton: false },
	},
	{
		path: 'account/magic-link-expired',
		component: MagicLinkExpiredComponent,
		data: { title: 'Link has Expired', animation: 'MagicLinkExpired', showBackButton: false },
	},
	{
		path: 'account/magic-link',
		component: MagicLinkExpiredComponent,
		data: { title: 'Loading', animation: 'MagicLinkExpired', showBackButton: false },
	},
	{
		path: 'account/check-email',
		component: MagicLinkSentComponent,
		data: { title: 'Check your email', animation: 'ConfirmEmail' },
	},
	{ path: 'env', component: EnvConfigComponent, canActivate: [ProdGuard] },
	{
		path: 'register',
		component: RegisterComponent,
		canActivate: [],
		data: { title: 'Create your account', animation: 'Register' },
	},
	{ path: 'error', component: ErrorComponent, data: { title: 'Error', showBackButton: false } },
	{ path: 'update', component: UpdateComponent, data: { title: 'Update Scan & Go App' } },
	{ path: '', redirectTo: 'enter-email', pathMatch: 'full' },
	{ path: '**', redirectTo: 'enter-email', pathMatch: 'full' },
];

@NgModule({
	declarations: [],
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
