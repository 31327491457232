import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export const charGroups = [/([a-z])/, /([A-Z])/, /([0-9])/, /([\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]|\s)/];

const passesCharMatchCriteria = (requiredGroupCount: any, input: any) =>
	Boolean(input.filter((r: any) => r != null).length >= requiredGroupCount);

/**
 * Validate against Password rules set out in a configuration object
 */
export const passwordValidator = (minimumLength = 8, requiredGroupCount = 2) => (
	control: UntypedFormGroup | UntypedFormControl
): { [key: string]: boolean } | null => {
	const error = {
		charMismatch: true,
		minlength: true,
	};

	const results: any = [];

	if (control.value.length >= minimumLength) {
		error.minlength = false;
	}

	charGroups.forEach((c) => {
		results.push(control.value.match(c));
	});

	if (passesCharMatchCriteria(requiredGroupCount, results)) {
		error.charMismatch = false;
	}
	if (error.charMismatch === false && error.minlength === false){
		return null;
	}
	return error;
};
