import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { deviceTypeSelector } from '../state/app.selectors';
import { AvailableApps } from '../utils';

@Component({
	selector: 'registration-update',
	templateUrl: './update.component.html',
	styleUrls: ['./update.component.css'],
})
export class UpdateComponent {
	deviceType$: AvailableApps;
	showButton = true;
	constructor(private readonly store: Store) {
		this.store.select(deviceTypeSelector).subscribe((deviceType) => {
			this.deviceType$ = deviceType;
			if (this.deviceType$ === 'ScanAndGoIOS') {
				this.showButton = false;
			}
		});
	}

	// extend the following logic to support more apps other than Scan&Go.
	// do nothing if it's not run in Scan&Go app.
	openMarket() {
		if (this.deviceType$ === 'ScanAndGoAndroid') {
			window.location.href = 'market://details?id=nz.co.countdown.scango';
		} else if (this.deviceType$ === 'ScanAndGoIOS') {
			window.open('https://itunes.apple.com/app/id1529370496');
		}
	}
}
