import { ChangeDetectionStrategy, Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppSettings } from '../../app.settings';
import { FormService, passwordValidator } from '@woolworthsnz/form';
import { Store } from '@ngrx/store';
import { deviceTypeSelector, emailSelector } from '../../state/app.selectors';
import { AvailableApps, Login } from '../../utils/type/registration.type';
import { Observable } from 'rxjs/internal/Observable';
import * as Actions from '../../state/app.actions';
import { EnvService, EnvServiceProvider } from '../../env.service';
import { ThemeService } from '../../utils/services/themes.service';
import { addUtmParameters } from '../../utils';
import { Router } from '@angular/router';

@Component({
	selector: 'registration-create-password',
	templateUrl: './create-password.component.html',
	styleUrls: ['./create-password.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [EnvServiceProvider],
})
export class CreatePasswordComponent implements OnInit, AfterViewInit {
	@Input() passwordLabel = 'Password *';
	@Input() submitButtonValue = 'Continue';
	deviceType$: AvailableApps;
	email$: string;
	form: UntypedFormGroup;
	themeConfigs$: Observable<string>;

	constructor(
		private fb: UntypedFormBuilder,
		public formService: FormService,
		private env: EnvService,
		private readonly store: Store,
		private themeService: ThemeService,
		private router: Router
	) {
		this.store.select(emailSelector).subscribe((email) => (this.email$ = email));
		this.store.select(deviceTypeSelector).subscribe((deviceType) => (this.deviceType$ = deviceType));
		this.themeConfigs$ = this.themeService.configs;
	}

	ngOnInit() {
		addUtmParameters(this.router);
		const { required } = Validators;
		const formControls = {
			password: ['', [required, passwordValidator()]],
		};
		this.form = this.fb.group(formControls, { updateOn: 'change' });
	}

	ngAfterViewInit() {
		setTimeout(() => {
			const input: HTMLInputElement = document.querySelector('#password');
			input?.focus();
		}, 200);
	}

	onSubmit() {
		if (this.form.valid && this.deviceType$) {
			const loginData: Login = {
				email: this.email$,
				password: this.form.get('password').value,
				sessionInfoType: AppSettings.sessionInfoType[this.deviceType$],
			};
			this.store.dispatch(Actions.create(loginData));
		}
	}

	get passwordCharMismatch() {
		return this.form.get('password').errors?.['charMismatch'];
	}
	get passwordMinimumLength() {
		return this.form.get('password').errors?.['minlength'];
	}
	get containsUpperCase() {
		return this.form.get('password').value.match(/[A-Z]/);
	}
	get containsLowercase() {
		return this.form.get('password').value.match(/[a-z]/);
	}
	get containsNumber() {
		return this.form.get('password').value.match(/[0-9]/);
	}
	get containsSpecialCharacter() {
		return this.form.get('password').value.match(/([\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]|\s)/);
	}
}
