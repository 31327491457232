<section class="main-section magic-link-section" data-testid="magiclinkSent">
	<h2>Check your email inbox</h2>
	<div class="form-content">
		<div *ngIf="email$" data-testid="emailSentToText">
			<cdx-alert
				[type]="'info'"
				data-testid="alert"
				class="info-alert"
				title="An email has been sent to"
				description="{{ email$ }}"
			>
			</cdx-alert>
		</div>
		<h5>Please open the email and follow the instructions.</h5>
		<p class="spam-text">It may take a few seconds to arrive. Please check your spam folder too.</p>
		<a class="link-text-registration" (click)="resendMagicLink()">Resend email</a>
	</div>
</section>
