import { AfterContentInit, Directive, ElementRef, Input, ViewContainerRef } from '@angular/core';
import { AppSettingsService } from '@woolworthsnz/styleguide';

@Directive({
	selector: '[formAutoFocus], [autoFocus]',
})
export class AutoFocusDirective implements AfterContentInit {
	@Input() autoFocus: boolean;

	public constructor(
		private el: ElementRef,
		private viewContainerRef: ViewContainerRef,
		private appSettingsService: AppSettingsService
	) {}

	public ngAfterContentInit() {
		const component = (<any>this.viewContainerRef)['_data']?.componentView?.component;

		setTimeout(() => {
			((component && component.input) || this.el).nativeElement.focus();
		}, parseInt(this.appSettingsService.getTransition('default'), 10));
	}
}
