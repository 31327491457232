<section class="container error-container">
	<div class="main">
		<h1>Oops! Login Failed.</h1>
		<p>
			We're having some trouble getting you logged in. No need to worry! Please log in again and we'll do our best to
			get you access to your account.
		</p>
		<p>Reference: {{ this.ref }}</p>
		<br />
		<div class="form-actions" *ngIf="!hideRetry">
			<button
				cdxButton
				class="submit-button"
				value="retry"
				tabindex="0"
				data-cy="'submit'"
				data-testid="submitButton"
				(click)="retry()"
			>
				Log in
			</button>
		</div>
	</div>
</section>
