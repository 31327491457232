import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import {
	LoginMagicLink,
	IfRegisteredResponse,
	VerifyMagicLink,
	Login,
	GetCustomerProfileResponse,
	RegisterCustomer,
} from '../utils/type/registration.type';
import { CryptoService } from '../utils/services/cryptoService.service';
import { EnvService, ENV_SERVICE } from '../env.service';
import { SendForgotPassword, UpdatePassword } from './app.state';
import { clientId, getTheme, getWelcomeEmailType } from '../utils';

@Injectable({
	providedIn: 'root',
})
export class AppService {
	constructor(
		private http: HttpClient,
		private cryptoService: CryptoService,
		@Inject(ENV_SERVICE) private _env: EnvService
	) {}

	configs = {
		'Content-Type': 'application/json',
		'WoolworthsNZ-Request-System-Id': clientId() ?? getTheme() ?? 'csx, bunch or unknown',
		'WoolworthsNZ-Request-User-Id': 'RegistrationUI',
	};

	checkIfRegistered(email: string) {
		const env = this._env.update();
		const headers = new HttpHeaders({
			'x-captcha': globalThis.v3token,
			'x-captcha-browser': globalThis.captchaErrorCount,
			...this.configs,
			'WoolworthsNZ-Request-User-Id': btoa(email),
		});
		const options = { headers };
		const QaUrl = `https://${env.registationAPI}${env.accountExistEndpoint}`;

		return this.http.post(QaUrl, { email }, options).pipe(map((response: IfRegisteredResponse) => response));
	}

	sendForgotPassword(sendForgotPassword: SendForgotPassword): Observable<any> {
		const env = this._env.update();
		const headers = new HttpHeaders({
			'x-captcha': globalThis.v3token,
			'x-captcha-browser': globalThis.captchaErrorCount,
			...this.configs,
			'WoolworthsNZ-Request-User-Id': btoa(sendForgotPassword?.email),
		});
		const options = { headers };
		const QaUrl = `https://${env.registationAPI}${env.sendForgotPasswordEndpoint}`;
		return this.http.post(QaUrl, { ...sendForgotPassword }, options).pipe(map((response: any) => response));
	}

	sendMagicLink(loginMagiclink: LoginMagicLink): Observable<any> {
		const env = this._env.update();
		const headers = new HttpHeaders({
			'x-captcha': globalThis.v3token,
			'x-captcha-browser': globalThis.captchaErrorCount,
			...this.configs,
			'WoolworthsNZ-Request-User-Id': btoa(loginMagiclink?.email),
		});
		const options = { headers };
		const url = `https://${env.registationAPI}${env.passwordless}`;
		const payload = {
			email: loginMagiclink.email,
			codeChallenge: this.cryptoService.generateCodeChallenge(loginMagiclink.codeVerifier),
			deviceType: loginMagiclink.deviceType,
			state: loginMagiclink.state,
			client: loginMagiclink.client,
		};
		return this.http.post(url, { ...payload }, options).pipe(map((response: any) => response));
	}

	verifyMagicLink(verifyMagiclink: VerifyMagicLink): Observable<any> {
		const env = this._env.update();
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'WoolworthsNZ-Request-System-Id': sessionStorage.getItem('tealiumClient'),
		});
		const options = { headers };
		const url = `https://${env.registationAPI}${env.passwordlessToken}`;
		return this.http.post(url, { ...verifyMagiclink }, options).pipe(map((response: any) => response));
	}

	login(login: Login): Observable<any> {
		const env = this._env.update();
		const headers = new HttpHeaders({
			'x-captcha': globalThis.v3token,
			'x-captcha-browser': globalThis.captchaErrorCount,
			...this.configs,
			'WoolworthsNZ-Request-User-Id': btoa(login?.email),
		});
		const options = { headers };
		const url = `https://${env.registationAPI}${env.loginEDR}`;
		return this.http.post(url, { ...login }, options).pipe(
			map((response: any) => {
				sessionStorage.setItem('session-response', response?.CookieInfo);
				return response;
			})
		);
	}

	registerCustomer(registerCustomer: RegisterCustomer): Observable<any> {
		const env = this._env.update();
		const headers = new HttpHeaders({
			'x-captcha': globalThis.v3token,
			'x-captcha-browser': globalThis.captchaErrorCount,
			...this.configs,
			'WoolworthsNZ-Request-User-Id': btoa(registerCustomer?.email),
		});
		const options = { headers };
		const url = `https://${env.registationAPI}${env.registerEDRCustomer}`;
		return this.http
			.post(url, { welcomeEmailType: getWelcomeEmailType(registerCustomer), ...registerCustomer }, options)
			.pipe(map((response: any) => response));
	}

	updateCustomer(updateCustomer: any, token): Observable<any> {
		const env = this._env.update();
		const headers = new HttpHeaders({ Authorization: `Bearer ${token}`, ...this.configs });
		const options = { headers };
		const url = `https://${env.registationAPI}${env.profile}`;
		return this.http
			.post(url, { ...updateCustomer }, options)
			.pipe(map((response: GetCustomerProfileResponse) => response));
	}

	updatePassword(updatePassword: UpdatePassword): Observable<any> {
		const env = this._env.update();
		const headers = new HttpHeaders({
			'x-captcha': globalThis.v3token,
			'x-captcha-browser': globalThis.captchaErrorCount,
			...this.configs,
		});
		const options = { headers };
		const url = `https://${env.registationAPI}${env.updatePasswordEndpoint}`;
		return this.http.post(url, { ...updatePassword }, options).pipe(map((response: any) => response));
	}

	getCustomerProfile(token: string): Observable<any> {
		const env = this._env.update();
		const headers = new HttpHeaders({ Authorization: `Bearer ${token}`, ...this.configs });
		const options = { headers };
		const url = `https://${env.registationAPI}${env.profile}`;
		return this.http.get(url, options).pipe(map((response: GetCustomerProfileResponse) => response));
	}
}
