import {
	OnInit,
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewChild,
	Inject,
	ElementRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { FormGroup, Validators } from '@angular/forms';
import { appStateSelector, emailSelector } from '../../state/app.selectors';
import { UntypedFormBuilder } from '@angular/forms';
import { FormService } from '@woolworthsnz/form';
import { WINDOW, CustomWindow } from '@woolworthsnz/styleguide';
import { EnvService } from '../../env.service';
import * as Actions from '../../state/app.actions';
import { AppState, SendForgotPassword } from '../../state/app.state';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { ThemeService } from '../../utils/services/themes.service';
import { addUtmParameters } from '../../utils';
import { Router } from '@angular/router';
@Component({
	selector: 'registration-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {
	@Input() emailLabel = 'Email address';
	@Input() submitButtonValue = 'Continue';
	@ViewChild('emailInput') emailInputField: ElementRef;
	form: FormGroup;
	email$: string;
	appstate$: Observable<AppState>;
	themeConfigs$: Observable<string>;
	currentTheme: string;

	constructor(
		private fb: UntypedFormBuilder,
		public formService: FormService,
		private readonly store: Store,
		private tealiumService: TealiumUtagService,
		private themeService: ThemeService,
		@Inject(EnvService) private _env: EnvService,
		@Inject(WINDOW) private window: CustomWindow,
		private router: Router
	) {
		this.store.select(emailSelector).subscribe((email) => (this.email$ = email));
		this.appstate$ = this.store.select(appStateSelector);
		this.themeConfigs$ = this.themeService.configs;
	}

	ngOnInit() {
		addUtmParameters(this.router);
		const { required, email, maxLength } = Validators;
		this.store.select(emailSelector).subscribe((emailAddress) => {
			const formControls = {
				emailInput: [emailAddress ?? '', [required, email, maxLength(50)]],
			};
			this.form = this.fb.group(formControls, { updateOn: 'submit' });
		});
		this.tealiumService.link({
			tealium_event: 'ssu_reset_password',
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			const input: HTMLInputElement = document.querySelector('#emailInput');
			input?.focus();
		}, 500);
	}

	onSubmit() {
		if (this.form.valid) {
			const email = this.form.get('emailInput')?.value?.trim();
			const urlParams = new URLSearchParams(window.location.search);
			const redirectUrl = urlParams.get('redirectUrl');
			globalThis.grecaptcha.enterprise.ready(() => {
				globalThis.grecaptcha.enterprise
					.execute(this._env.reCaptchaKey, { action: 'forgot_password' })
					.then((token: string) => {
						globalThis.v3token = token;
						const data: SendForgotPassword = { email, client: this.themeService.currentTheme };
						if (redirectUrl) {
							data.redirectUrl = redirectUrl;
						}
						this.store.dispatch(Actions.sendForgotPassword(data));
					});
			});
		}
	}
}
