import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';

@Component({
	selector: 'form-password-input',
	templateUrl: './password-input.component.html',
	styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit {
	@Input() showPasswordHelpText: boolean;
	@Input() showInputError = true;
	@Input() passwordLabel: string;
	@Input() autocomplete: string;
	@Input() passwordrules: string;
	@Input() validationMessage = 'Please enter a valid password';
	type: 'text' | 'password' = 'password';
	parentFormGroup: UntypedFormGroup;

	get state() {
		return this.type && this.type === 'text' ? 'Hide' : 'Show';
	}

	get buttonLabel() {
		return this.state + ' password';
	}

	constructor(private parent: FormGroupDirective) {}

	ngOnInit(): void {
		this.parentFormGroup = this.parent.form;
	}

	onButtonChange($event: { stopPropagation: () => void; preventDefault: () => void }) {
		$event.stopPropagation();
		$event.preventDefault();
		return false;
	}

	toggleInputType() {
		if (this.type && this.type === 'text') {
			this.type = 'password';
		} else {
			this.type = 'text';
		}
	}
}
