import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { InputErrorComponent } from '../components/input-error/input-error.component';

@Directive({
	selector: '[formInvalidType], [cdxInvalidType]',
})
export class InvalidTypeDirective implements OnInit {
	@Input('cdxInvalidType') type: string;

	injectedView: any;

	constructor(private inputError: InputErrorComponent, private el: ElementRef) {}

	ngOnInit() {
		this.el.nativeElement.classList.add('form-errorText--hide');
		this.inputError.controlStatus$.subscribe(() => {
			this.setVisible();
		});
	}

	private setVisible() {
		const hasError = this.inputError.getErrorType(this.type);
		// below code has been written to make it work in IE11
		// toggle or replace wouldnt work in all browsers
		if (hasError) {
			this.el.nativeElement.classList.remove('form-errorText--hide');
			this.el.nativeElement.classList.add('form-errorText');
		} else {
			this.el.nativeElement.classList.add('form-errorText--hide');
			this.el.nativeElement.classList.remove('form-errorText');
		}
	}
}
