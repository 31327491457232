import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostBinding,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

export enum InputSize {
	narrow = 'narrow',
	medium = 'medium',
	fullWidth = 'full-width',
	initial = 'initial',
}

@Component({
	selector: 'form-input, [cdxInput]',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputComponent),
			multi: true,
		},
	],
})
export class InputComponent implements ControlValueAccessor, AfterViewInit {
	get isCharInput() {
		return !this.isCheckbox && !this.isRadio;
	}

	get isCheckbox() {
		return this.inputType === 'checkbox';
	}

	get isRadio() {
		return this.inputType === 'radio';
	}

	get isTextarea() {
		return this.inputType === 'textarea';
	}
	@HostBinding('attr.data-unstyled')
	@Input()
	unstyled = false; // TODO: Check if used

	@Input() autocomplete: string;
	@Input() passwordrules: string;
	@Input() checked: boolean;
	@Input() colour: any;
	@Input() formControlName: string;
	@Input() id: string;
	@Input() inputType = 'text';
	@Input() inputValue = null;
	@Input() maxlength: string;
	@Input() name: string;
	@Input() placeholder: string;
	@Input() activeDescendant: string;
	@HostBinding('attr.data-size')
	@Input()
	size: InputSize = InputSize.initial;
	@Input() tabindex: number;
	@Input() selectOnClick = false;
	@Input() successIcon = false;
	@Input() disableInput = false;
	@Input() maskingFn: (val: string) => any;
	@Input() onKeyupMaskingFn: (val: string, event?: KeyboardEvent) => any;
	@Input() inputMode: 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | null = null;
	@Input() rows: number;
	@Input() columns: number;

	/* eslint-disable @angular-eslint/no-output-native */
	@Output() keyup: EventEmitter<string> = new EventEmitter();
	@Output() blur: EventEmitter<Event> = new EventEmitter();
	@Output() focus: EventEmitter<Event> = new EventEmitter();
	/* eslint-enable @angular-eslint/no-output-native */

	@ViewChild('input', { read: ElementRef }) input: ElementRef;
	disabled: boolean | null;
	controlIsInvalid = false;
	ngControl: NgControl;

	onChangeCallback: (_: any) => void;
	onTouchedCallback: (_: any) => void;

	ngAfterViewInit() {
		if (!this.isCharInput) {
			this.input.nativeElement.checked = this.inputValue;
		}
	}

	onKeyup = (value: string | undefined, event?: KeyboardEvent) => {
		if (this.onKeyupMaskingFn && value && event) {
			value = this.onKeyupMaskingFn(value);
		}
		this.keyup.emit(value);
		this.writeValue(value);
	};

	onKeydown = (value: string | undefined, event: KeyboardEvent) => {
		if(this.inputType==="text" && this.inputMode==="numeric" ) {
			if (
				event.ctrlKey === true ||
				event.metaKey === true ||
				event.key === 'Backspace' ||
				event.key === 'Enter' ||
				event.key === 'ArrowLeft' ||
				event.key === 'ArrowUp' ||
				event.key === 'ArrowDown' ||
				event.key === 'ArrowRight' ||
				event.key === 'Tab' ||
				event.key === 'Delete' ||
				event.key === 'PageDown' ||
				event.key === 'PageUp' ||
				event.key === 'End' ||
				event.key === 'Home'
			) {
				return;
			};

			if (/\D+/i.test(event.key)) {
			    return false
			};
		};

		this.keyup.emit(value);
		this.writeValue(value);
	};

	onBlur = ($event: FocusEvent) => {
		this.blur.emit($event);
		this.onChange($event);
	};

	onFocus = ($event: FocusEvent) => {
		this.focus.emit($event);
		this.selectValue();
	};

	onChange = ($event: any) => {
		const returnValue = this.isCheckbox || this.isRadio ? $event.target.checked : $event.target.value;

		if (this.onChangeCallback) this.onChangeCallback(returnValue);
		if (this.onTouchedCallback) this.onTouchedCallback($event);
	};

	get nativeElement() {
		return this.input.nativeElement;
	}

	onClick = () => (this.selectOnClick ? this.selectValue() : null);

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	selectValue = () => this.selectOnClick && this.input && this.input.nativeElement.select();

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled || null;
	}

	writeValue(value: any): void {
		if (!this.isCharInput && this.input) {
			this.input.nativeElement.checked = value;
		}

		if (this.maskingFn && value) {
			value = this.maskingFn(value);
		}

		this.inputValue = value;

		if (this.onChangeCallback) {
			this.onChangeCallback(value);
		}
	}

	onPaste(event: ClipboardEvent) {
		const clipboardData = event.clipboardData;
		const pastedText = clipboardData.getData('text');
		if (this.inputType === 'text' && this.inputMode === 'numeric') {
			if (/\D+/i.test(pastedText)) {
				return false;
			}
		}
	}
}
