<section class="main-section">
	<h1>Set new Password</h1>
	<cdx-alert
		[type]="'error'"
		class="error-alert"
		title="Reset password link expired"
		[description]="(appstate$ | async).updatePasswordExpired"
		*ngIf="(appstate$ | async).updatePasswordExpired"
		data-testid="updatePasswordExpired"
	>
		<a
			alertLink
			*ngIf="showResendEmail()"
			class="link-text-registration"
			tabindex="0"
			[routerLink]="['/enter-email']"
			data-testid="resendEmail"
			>Resend Email</a
		>
	</cdx-alert>
	<cdx-alert
		[type]="'error'"
		class="error-alert"
		title="{{ (themeConfigs$ | async).serverErrorTitle }}"
		description="{{ (themeConfigs$ | async).serverErrorText }}"
		*ngIf="(appstate$ | async).updatePasswordFailed"
		data-testid="updatePasswordFailed"
	>
	</cdx-alert>
	<form class="form-content" (ngSubmit)="onSubmit()" [formGroup]="form" data-testid="passwordForm">
		<div>
			<fieldset
				cdxFieldset
				class="set-password-fieldset"
				[divider]="false"
				hideLegend="true"
				[legend]="'create password'"
				[i18n]="'@@register-createPasswordLabel'"
			>
				<label label>New Password <span class="asterisk">*</span></label>
				<form-password-input
					[showPasswordHelpText]="false"
					data-cy="'password'"
					class="create-password"
					[showInputError]="false"
					passwordrules="minlength: 8; required: lower; required: upper; required: digit; required: [ !&quot;#$%&'()*+,./:;<=>?@\^_`{|}~];"
				>
				</form-password-input>
				<div class="password-requirement">
					<div>
						<cdx-svg-icon
							name="cross"
							size="small"
							fill="dark"
							display="inline"
							*ngIf="passwordMinimumLength; else requirementPassed"
							data-testid="password-requirement-notMetLength"
						></cdx-svg-icon>
					</div>
					<span
						[ngClass]="{
							'password-validation': passwordMinimumLength,
							'password-validation-green': !passwordMinimumLength
						}"
						i18n="@@passwordHelpText--minLength"
						>Password must contain at least 8 characters</span
					>
				</div>
				<div class="password-requirement">
					<div>
						<cdx-svg-icon
							name="cross"
							size="small"
							fill="dark"
							display="inline"
							*ngIf="passwordCharMismatch; else requirementPassed"
							data-testid="passwordRequirementNotMetChar"
						></cdx-svg-icon>
					</div>
					<span class="password-validation"
						>Include at least two of the following:
						<p class="password-validation-margin">
							<span
								[ngClass]="{
									'password-validation-small': !containsUpperCase,
									'password-validation-small-green': containsUpperCase
								}"
								i18n="@@passwordHelpText--upperLower"
								>Upper case</span
							>
							|
							<span
								[ngClass]="{
									'password-validation-small': !containsLowercase,
									'password-validation-small-green': containsLowercase
								}"
								i18n="@@passwordHelpText--upperLower"
								>Lower case</span
							>
							|
							<span
								[ngClass]="{
									'password-validation-small': !containsNumber,
									'password-validation-small-green': containsNumber
								}"
								i18n="@@passwordHelpText--number"
								>A number</span
							>
							|
							<span
								[ngClass]="{
									'password-validation-small': !containsSpecialCharacter,
									'password-validation-small-green': containsSpecialCharacter
								}"
								i18n="@@passwordHelpText--special"
								>A special character</span
							>
						</p>
					</span>
				</div>
				<ng-template #requirementPassed>
					<cdx-svg-icon
						class="success-green"
						name="tick"
						size="small"
						fill="currentColor"
						display="inline"
						data-testid="passwordRequirementMet"
					></cdx-svg-icon>
				</ng-template>
			</fieldset>
		</div>
		<div>
			<fieldset
				cdxFieldset
				[divider]="false"
				hideLegend="true"
				[legend]="'confirm password'"
				[i18n]="'@@register-confirmPasswordLabel'"
			>
				<label label>Confirm Password <span class="asterisk">*</span></label>
				<form-input-with-icon-button
					formControlName="confirmPassword"
					data-testid="'confirmPassword'"
					class="confirm-password"
					[inputType]="type"
				>
					<button
						btn
						id="showPasswordButton"
						class="passwordButton"
						fillStyle="flat"
						type="button"
						[ngClass]="{ hidePassword: type === 'text' }"
						name="buttonLabel"
						[attr.aria-label]="buttonLabel"
						cdxButton
						(click)="toggleInputType()"
						(blur)="onButtonChange($event)"
						(change)="onButtonChange($event)"
					>
						&nbsp;
					</button>
				</form-input-with-icon-button>
				<form-input-error validation control="confirmPassword" data-cy="'password_err'">
					<p
						class="invalid-password-error"
						[cdxInvalidType]="'*'"
						i18n="@@register-invalidPassword"
						data-testid="passwordError"
					>
						Passwords do not match
					</p>
				</form-input-error>
			</fieldset>
		</div>

		<div class="form-actions">
			<button
				cdxButton
				class="submit-button"
				value="Submit"
				type="submit"
				tabindex="0"
				data-cy="'submit'"
				data-testid="submitButton"
				[disabled]="!form.valid"
			>
				{{ submitButtonValue }}
			</button>
		</div>
	</form>
</section>
