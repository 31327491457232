import { Inject, Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { EnvService, ENV_SERVICE } from '../../env.service';

@Injectable({
	providedIn: 'root',
})
export class AppInsightLoggingService {
	appInsights: ApplicationInsights;
	constructor(@Inject(ENV_SERVICE) private _env: EnvService) {}

	initiailize() {
		const env = this._env.update();
		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: env.appInsightInstructmentKey,
				enableAutoRouteTracking: true, // option to log all route changes
			},
		});
		this.appInsights?.loadAppInsights();
	}

	logPageView(name?: string, url?: string) {
		// option to call manually
		this.appInsights?.trackPageView({
			name: name,
			uri: url,
		});
	}

	logEvent(name: string, properties?: { [key: string]: any }) {
		this.appInsights?.trackEvent({ name: name }, properties);
	}

	logMetric(name: string, average: number, properties?: { [key: string]: any }) {
		this.appInsights?.trackMetric({ name: name, average: average }, properties);
	}

	logException(exception: Error, severityLevel?: number) {
		this.appInsights?.trackException({ exception: exception, severityLevel: severityLevel });
	}

	logTrace(message: string, properties?: { [key: string]: any }) {
		this.appInsights?.trackTrace({ message: message }, properties);
	}
}
