import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputComponent } from '../input/input.component';

@Component({
	selector: 'form-input-with-icon-button',
	template: `
		<ng-content select="[label]"></ng-content>
		<div [ngClass]="{ 'has-error': controlIsInvalid }" class="inputContainer" [attr.readonly]="disabled">
			<input
				#input
				type="{{ inputType }}"
				id="{{ id || formControlName }}"
				name="{{ name || formControlName }}"
				attr.data-cy="{{ formControlName }}"
				attr.data-testid="{{ formControlName }}Input"
				placeholder="{{ placeholder }}"
				[attr.data-size]="size"
				[attr.maxLength]="maxlength"
				[attr.readonly]="disabled"
				[attr.tabindex]="tabindex"
				[ngClass]="{
					successIcon: successIcon
				}"
				[value]="inputValue"
				(blur)="onBlur($event)"
				(change)="onChange($event)"
				(keyup)="onKeyup(input.value)"
				(focus)="onFocus(input.value)"
				[attr.autocomplete]="autocomplete"
				[attr.autocorrect]="autocorrect"
				[attr.aria-activedescendant]="activeDescendant"
				[attr.passwordrules]="passwordrules"
			/>
			<ng-content select="[btn]"></ng-content>
		</div>
		<ng-content select="[validation]"></ng-content>
		<ng-content select="[cdxFormHelpText]"></ng-content>
	`,
	styleUrls: ['./input-with-icon-button.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: forwardRef(() => InputWithIconButtonComponent),
			multi: true,
		},
		{
			provide: InputComponent,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: forwardRef(() => InputWithIconButtonComponent),
		},
	],
})
export class InputWithIconButtonComponent extends InputComponent {
	@Input() inputType: 'text' | 'password' = 'text';
	@Input() autocorrect: string;
	@Input() activeDescendant: string;
}
