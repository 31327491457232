import { Component, OnInit, Input } from '@angular/core';
import { EnvService } from '../../env.service';
import { Observable } from 'rxjs';
import { ThemeService } from '../../utils/services/themes.service';
import { Router } from '@angular/router';
import { addUtmParameters } from '../../utils';
@Component({
	selector: 'registration-reset-password-success',
	templateUrl: './reset-password-success.component.html',
	styleUrls: ['./reset-password-success.component.scss'],
})
export class ResetPasswordSuccessComponent implements OnInit {
	@Input() submitButtonValue = 'Back';
	themeConfigs$: Observable<string>;
	showButton = false;
	constructor(private env: EnvService, private themeConfigs: ThemeService, private router: Router) {
		this.themeConfigs$ = this.themeConfigs.configs;
		const updatePasswordRedirectUrl = sessionStorage.getItem('updatePasswordRedirectUrl');
		if (updatePasswordRedirectUrl) {
			this.showButton = true;
		}
	}

	onSubmit() {
		const updatePasswordRedirectUrl = sessionStorage.getItem('updatePasswordRedirectUrl');
		if (updatePasswordRedirectUrl) {
			return (window.location.href = decodeURIComponent(updatePasswordRedirectUrl));
		}
	}

	ngOnInit() {
		addUtmParameters(this.router);
		return null;
	}
}
