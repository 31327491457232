import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	AfterViewInit,
	Inject,
} from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormService } from '@woolworthsnz/form';
import { WINDOW, CustomWindow } from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { EnvService, EnvServiceProvider } from '../../env.service';
import * as Actions from '../../state/app.actions';
import { emailSelector, appStateSelector } from '../../state/app.selectors';
import { AppState } from '../../state/app.state';
import { AppInsightLoggingService } from '../../utils/services/appInsightLogging.service';
import { addUtmParameters, isMobileWebview } from '../../utils';
import { ThemeService } from '../../utils/services/themes.service';
import { expand } from '../../app.animations';
import { Router } from '@angular/router';
@Component({
	selector: 'registration-enter-email',
	templateUrl: './enter-email.component.html',
	styleUrls: ['./enter-email.component.scss'],
	providers: [EnvServiceProvider],
	animations: [expand],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnterEmailComponent implements OnInit, AfterViewInit {
	@Input() emailLabel = 'Email address';
	@Input() submitButtonValue = 'Continue';
	@ViewChild('emailInput') emailInputField: ElementRef;
	form: UntypedFormGroup;
	email$: string;
	isRegister = false;
	appstate$: Observable<AppState>;
	themeConfigs$: Observable<string>;
	learnMoreLink$: Observable<string>;
	panelStatus = 'close';

	constructor(
		private fb: UntypedFormBuilder,
		public formService: FormService,
		private env: EnvService,
		private readonly store: Store,
		private appInsight: AppInsightLoggingService,
		private themeService: ThemeService,
		@Inject(WINDOW) private window: CustomWindow,
		private router: Router
	) {
		this.store.select(emailSelector).subscribe((email) => (this.email$ = email));
		this.appstate$ = this.store.select(appStateSelector);
		this.themeConfigs$ = this.themeService.configs;
	}

	ngOnInit() {
		addUtmParameters(this.router);

		const { required, email, maxLength } = Validators;
		this.store.select(emailSelector).subscribe((emailAddress) => {
			const formControls = {
				emailInput: [emailAddress ?? '', [required, email, maxLength(50)]],
			};
			this.form = this.fb.group(formControls, { updateOn: 'submit' });
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			const input: HTMLInputElement = document.querySelector('#emailInput');
			input?.focus();
		}, 500);
	}

	onSubmit() {
		if (this.form.valid) {
			globalThis.grecaptcha.enterprise.ready(() => {
				globalThis.grecaptcha.enterprise
					.execute(this.env.reCaptchaKey, { action: 'registration_available' })
					.then((token: string) => {
						globalThis.v3token = token;
						if (!token || token.length === 0) {
							this.appInsight?.logEvent('edr-empty-token');
						}
						this.store.dispatch(Actions.checkIfRegistered({ email: this.form.get('emailInput')?.value?.trim() }));
					});
			});
		}
	}

	toggleItem() {
		this.panelStatus = this.panelStatus === 'close' ? 'open' : 'close';
	}

	getChevronStyle() {
		if (this.panelStatus === 'open') {
			return { transform: 'rotate(-180deg)' };
		}
		return { transform: 'rotate(0deg)' };
	}

	goToCountdown() {
		this.store.dispatch(Actions.resetUiError());
		if (isMobileWebview()) {
			return (this.window.location.href = '/');
		}
		const redirectUrl = `${this.env.shopURL}/shop/register/shopperdetails`;
		this.window.location.href = `${this.env.shopURL}/api/v1/bff/initiate-oidc-signin?redirectUrl=${encodeURIComponent(
			redirectUrl
		)}`;
	}
	handleAlertClosed() {
		this.store.dispatch(Actions.resetUiError());
	}
}
