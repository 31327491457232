/* NgRx */
import { createAction, props } from '@ngrx/store';
import {
	LoginMagicLink,
	VerifyMagicLink,
	Sessions,
	AvailableApps,
	Login,
	CustomerInfo,
	RegisterCustomer,
	GetCustomerProfile,
} from '../utils/type/registration.type';
import { SendForgotPassword, UpdatePassword } from './app.state';

// State
export const setDeviceType = createAction('[Registration UI] setDeviceType', props<{ deviceType: AvailableApps }>());
export const resetUiError = createAction('[Registration UI] resetUiError');
export const setApiNotAvailable = createAction('[Registration UI] setApiNotAvailable', props<{ msg: string }>());

// Enter email
export const checkIfRegistered = createAction('[Registration API] checkIfRegistered', props<{ email: string }>());
export const setRegistered = createAction('[Registration API] setRegistered', props<{ isAvailable: boolean }>());

// Enter email errors
export const registeredInactive = createAction(
	'[Registration API] Registered email is inactive',
	props<{ msg: string }>()
);
export const checkIfRegisteredFailed = createAction(
	'[Registration API] checkIfRegisteredFailed',
	props<{ msg: string }>()
);
// Enter password
export const login = createAction('[Registration API] login', props<Login>());
export const loginFailed = createAction('[Registration API] loginFailed', props<{ title: string; msg: string }>());
export const loginServerError = createAction('[Registration API] loginServerError', props<{ msg: string }>());
export const loginSuccess = createAction('[Registration API] loginSuccess', props<Sessions>());

// Create password
export const create = createAction('[Registration API] save login data', props<Login>());
export const createSuccess = createAction('[Registration API] save login data success');

// Register customer
export const registerCustomer = createAction('[Registration API] registerCustomer', props<RegisterCustomer>());
export const registerCustomerSuccess = createAction('[Registration API] registerCustomerSuccess', props<Sessions>());
export const registerCustomerFailed = createAction(
	'[Registration API] registerCustomerFailed',
	props<{ msg: string }>()
);
export const saveCustomerInfoForLogin = createAction(
	'[Registration API] saveCustomerInfoForLogin',
	props<CustomerInfo>()
);

// Get profile info
export const saveCustomerInfo = createAction('[Registration API] saveCustomerInfo', props<CustomerInfo>());
export const getCustomerProfile = createAction('[Registration API] getCustomerProfile', props<GetCustomerProfile>());
export const getCustomerProfileSuccess = createAction('[Registration API] getCustomerProfileSuccess');

// Forgot password
export const sendForgotPassword = createAction('[Registration API] sendForgotPassword', props<SendForgotPassword>());
export const sendForgotPasswordFailed = createAction('[Registration API] sendForgotFailed', props<{ msg: string }>());
export const sendForgotPasswordSuccess = createAction('[Registration API] sendForgotSuccess');

// Update password
export const updatePassword = createAction('[Registration API] updatePassword', props<UpdatePassword>());
export const updatePasswordSuccess = createAction('[Registration API] updatePasswordSuccess');
export const updatePasswordExpired = createAction('[Registration API] updatePasswordExpired', props<{ msg: string }>());
export const updatePasswordFailed = createAction('[Registration API] updatePasswordFailed', props<{ msg: string }>());

// Magic link
export const sendMagicLink = createAction('[Registration API] sendMagicLink', props<LoginMagicLink>());
export const resendMagicLink = createAction('[Registration API] resendMagicLink', props<LoginMagicLink>());
export const sendMagicLinkSuccess = createAction('[Registration API] sendMagicLinkSuccess');
export const sendMagicLinkFailed = createAction('[Registration API] sendMagicLinkFailed', props<{ msg: string }>());

// Magic link failed
export const verifyMagicLink = createAction('[Registration API] verifydMagicLink', props<VerifyMagicLink>());
export const verifyMagicLinkSuccess = createAction('[Registration API] verifydMagicLinkSuccess', props<Sessions>());
export const verifyMagicLinkFailed = createAction(
	'[Registration API] verifydMagicLinkFailed',
	props<{ msg: string }>()
);

// Redirect
export const redirected = createAction('[SSU] user has been redirected to other page');
export const redirectedToProxy = createAction('[SSU] user has been redirected to OIDC proxy page');

export const clearUserFormdata = createAction('[Registration API] clear user form data');
export const clearSessiondata = createAction('[Registration API] clear session data');
