<footer>
	<div class="footer__nav">
		<div class="footer__nav-container">
			<p>© Woolworths New Zealand Limited {{ currentYear }}. All rights reserved.</p>
			<ul>
				<li *ngFor="let navItem of navItems">
					<a [href]="navItem.url" target="_blank">{{ navItem.label }}</a>
				</li>
			</ul>
		</div>
	</div>
</footer>
