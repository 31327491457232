import { Router } from '@angular/router';
import { AppSettings } from '../../app.settings';
import { RegisterCustomer } from '../type/registration.type';

export const isOIDC = () => {
	const gigyaCache = sessionStorage.getItem('gigyaCache');
	// Gigya set empty object string in local storage aftger logout
	return gigyaCache && gigyaCache !== '{}';
};

export const getTheme = (): string => {
	try {
		const gigyaCache = JSON.parse(sessionStorage.getItem('gigyaCache'));
		if (gigyaCache) {
			const data = gigyaCache[Object.keys(gigyaCache)[Object.keys(gigyaCache).length - 1]];
			if (data?.response && Array.isArray(data?.response)) {
				const numbers = data?.response?.length;
				let display = data?.response[numbers - 1]?.display;
				// version 2 url params
				if (display?.indexOf('theme') > -1) {
					const displaylParams = new URLSearchParams(display);
					display = displaylParams.get('theme');
					if (display) {
						return display;
					}
				}
				return display;
			}
		}
	} catch (error) {
		return null;
	}
};

export const getParnerId = (): string => {
	try {
		const gigyaCache = JSON.parse(sessionStorage.getItem('gigyaCache'));
		if (gigyaCache) {
			const data = gigyaCache[Object.keys(gigyaCache)[Object.keys(gigyaCache).length - 1]];
			if (data?.response && Array.isArray(data?.response)) {
				const numbers = data?.response?.length;
				const display = data?.response[numbers - 1]?.display;
				// version 2 url params
				if (display?.indexOf('theme') > -1 && display?.indexOf('partnerId') > -1) {
					const displaylParams = new URLSearchParams(display);
					const partnerId = displaylParams.get('partnerId');
					if (partnerId) {
						return partnerId;
					}
				}
				return null;
			}
		}
	} catch (error) {
		return null;
	}
};

export const getDisplaylParams = (): URLSearchParams => {
	try {
		const gigyaCache = JSON.parse(sessionStorage.getItem('gigyaCache'));
		if (gigyaCache) {
			const data = gigyaCache[Object.keys(gigyaCache)[Object.keys(gigyaCache).length - 1]];
			if (data?.response && Array.isArray(data?.response)) {
				const numbers = data?.response?.length;
				const display = data?.response[numbers - 1]?.display;
				// version 2 url params
				if (display?.indexOf('theme') > -1 && display?.indexOf('partnerId') > -1) {
					const displaylParams = new URLSearchParams(display);
					return displaylParams;
				}
				return null;
			} else {
				return null;
			}
		}
	} catch (error) {
		return null;
	}
};

export const clientId = (): string => {
	try {
		const gigyaCache = JSON.parse(sessionStorage.getItem('gigyaCache'));
		if (gigyaCache) {
			const data = gigyaCache[Object.keys(gigyaCache)[Object.keys(gigyaCache).length - 1]];
			if (data?.response && Array.isArray(data?.response)) {
				const numbers = data?.response?.length;
				const display = data?.response[numbers - 1]?.display;
				// version 2 url params
				if (display?.indexOf('theme') > -1 && display?.indexOf('clientId') > -1) {
					const displaylParams = new URLSearchParams(display);
					const clientID = displaylParams.get('clientId');
					if (clientID) {
						return clientID;
					}
				}
				return null;
			}
		}
	} catch (error) {
		return null;
	}
};

export const hasValidGigyaCache = (): boolean => {
	try {
		const gigyaCache = JSON.parse(sessionStorage.getItem('gigyaCache'));
		let validCache = true;

		if (!gigyaCache || Object.keys(gigyaCache).length === 0) {
			validCache = false;
		}
		if (gigyaCache) {
			const data = gigyaCache[Object.keys(gigyaCache)[Object.keys(gigyaCache).length - 1]];
			if (data?.response && Array.isArray(data?.response)) {
				const numbers = data?.response?.length;
				const clientID = data?.response[numbers - 1]?.clientID;
				if (!clientID) {
					validCache = false;
				}
			} else {
				validCache = false;
			}
		}

		return validCache;
	} catch (error) {
		return false;
	}
};

export const hideHeaderAndFooter = () => clientId() === 'edr_app';

export const isEDRtheme = () => getTheme() === 'edr';

export const isMobileWebview = () => getTheme() === 'myCountdownApp' || clientId() === 'edr_app';

export const useOIDCTheme = (deviceType?: string): boolean => {
	if (deviceType) {
		return AppSettings.oidcThemeClients.includes(deviceType);
	}

	try {
		const app = JSON.parse(sessionStorage.getItem('app'));
		return AppSettings.oidcThemeClients.includes(app.deviceType);
	} catch (e) {
		// log error
		window.location.href = '/error';
	}
};

export const isTrader = (): boolean => {
	try {
		const app = JSON.parse(sessionStorage.getItem('app'));
		return app.deviceType === 'Trader';
	} catch (e) {
		// log error
		window.location.href = '/error';
	}
};

export const isMobileMagiclink = (): boolean => {
	const name = sessionStorage.getItem('magicLinkCache');
	return name && AppSettings.sessionInfoType[name] === 'Mobile';
};

export const getWelcomeEmailType = (registerCustomer: RegisterCustomer): string => {
	if (getTheme() === 'edr') {
		if (registerCustomer.edrSetting) {
			// bp
			if (getParnerId() === '2011') {
				return 'bp_marketing';
			}
			return 'edr_marketing';
		} else {
			// bp
			if (getParnerId() === '2011') {
				return 'bp_transactional';
			}
			return 'edr_transactional';
		}
	} else {
		if (registerCustomer.countdownSetting) {
			return 'cdx_marketing';
		} else {
			return 'cdx_transactional';
		}
	}
};

export const resetCookies = (): void => {
	const cookies = document.cookie.split('; ');
	for (let c = 0; c < cookies.length; c++) {
		const d = window.location.hostname.split('.');
		while (d.length > 0) {
			const cookieBase =
				encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
				'=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
				d.join('.') +
				' ;path=';
			const p = location.pathname.split('/');
			document.cookie = cookieBase + '/';
			while (p.length > 0) {
				document.cookie = cookieBase + p.join('/');
				p.pop();
			}
			d.shift();
		}
	}
};

const UTM_SOURCE = 'utm_source';

export const addUtmParameters = (router: Router) => {
	const urlParams = new URLSearchParams(window.location.search);
	const utmSourceParam = urlParams.get(UTM_SOURCE);

	if (!utmSourceParam) {
		const displaylParams = getDisplaylParams();

		let queryParams = {};
		if (displaylParams) {
			queryParams = {
				...queryParams,
				utm_source: displaylParams.get('utm_source'),
				utm_medium: displaylParams.get('utm_medium'),
				utm_campaign: displaylParams.get('utm_campaign'),
				utm_id: displaylParams.get('utm_id'),
				utm_term: displaylParams.get('utm_term'),
				utm_content: displaylParams.get('utm_content'),
			};
		}

		if (UTM_SOURCE in queryParams) {
			router.navigate([], {
				queryParams: queryParams,
				queryParamsHandling: 'merge',
				preserveFragment: true,
			});
		}
	}
};

export const loadScript = (url: string): void => {
	const script = document.createElement('script');
	script.src = url;
	document.head.appendChild(script);
};
