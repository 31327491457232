import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
	transition(
		`EnterEmail => EnterPassword,
		EnterEmail => CreatePassword,
		EnterPassword => ConfirmEmail,
		EnterPassword => ResetPassword,
		EnterPassword => Register,
		ResetPassword => ResetPasswordSentEmail,
		SetNewPassword => ResetPasswordSuccess,
		ResetPasswordSuccess => EnterEmail,
		CreatePassword => Register,
		MagicLinkExpired => EnterEmail`,
		[
			style({ position: 'relative' }),
			query(':enter, :leave', [
				style({
					position: 'absolute',
					top: 0,
					right: 0,
					width: '100%',
				}),
			]),
			query(':enter', [style({ right: '-100%' })]),
			query(':leave', animateChild()),
			group([
				query(':leave', [animate('300ms ease-out', style({ right: '110%' }))]),
				query(':enter', [animate('300ms  ease-out', style({ right: '0%' }))]),
			]),
			query(':enter', animateChild()),
		]
	),
	transition(
		`EnterPassword => EnterEmail,
		CreatePassword => EnterEmail,
		ConfirmEmail => EnterPassword,
		ResetPassword => EnterPassword,
		Register => EnterPassword,
		ResetPasswordSentEmail => ResetPassword,
		ResetPasswordSuccess => SetNewPassword,
		Register => CreatePassword,
		EnterEmail => MagicLinkExpired`,
		[
			style({ position: 'relative' }),
			query(':enter, :leave', [
				style({
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
				}),
			]),
			query(':enter', [style({ left: '-100%' })]),
			query(':leave', animateChild()),
			group([
				query(':leave', [animate('300ms ease-out', style({ left: '110%' }))]),
				query(':enter', [animate('300ms ease-out', style({ left: '0%' }))]),
			]),
			query(':enter', animateChild()),
		]
	),
]);

export const expand = trigger('expand', [
	state('close', style({ height: '0px', overflow: 'hidden' })),
	state('open', style({ height: '*', overflow: 'hidden' })),
	transition('open <=> close', animate('300ms ease-in-out')),
]);
