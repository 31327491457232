import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../themes/app.routes';
import { Store } from '@ngrx/store';
import { emailSelector, userSelector, deviceTypeSelector, appStateSelector } from '../state/app.selectors';
import { AppState, SessionState } from '../state/app.state';
import * as Actions from '../state/app.actions';
import { CustomerInfo, addUtmParameters } from '../utils';
import { AppSettings } from '../app.settings';
import { FormService } from '@woolworthsnz/form';
import { validateMobileWithMask, validateNameWithMask, AvailableApps } from '../utils';
import { Observable } from 'rxjs';
import { EnvService } from '../env.service';
import { ThemeService } from '../utils/services/themes.service';
declare const grecaptcha: any;
@Component({
	selector: 'registration-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
	providers: [FormService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
	@Input() submitButtonValue = 'Confirm';
	session$: SessionState;
	deviceType$: AvailableApps;
	form: UntypedFormGroup;
	user$: CustomerInfo;
	email$: string;
	updateCustomerFailed$: Observable<string>;
	updateCustomerSuccess$: Observable<boolean>;
	appState$: Observable<AppState>;
	updateMode: boolean;
	themeConfigs$: Observable<string>;

	edrTermsAndConditionsLink = APP_ROUTES.edr.TermsAndConditions;
	cdxTermsAndConditionsLink = APP_ROUTES.cdx.TermsAndConditions;
	cdxPrivacyCentre = APP_ROUTES.cdx.Privacy;

	constructor(
		private fb: UntypedFormBuilder,
		private router: Router,
		private readonly store: Store,
		private env: EnvService,
		private themeService: ThemeService
	) {
		this.store.select(userSelector).subscribe((user) => (this.user$ = user));
		this.store.select(emailSelector).subscribe((email) => (this.email$ = email));
		this.store.select(deviceTypeSelector).subscribe((deviceType) => (this.deviceType$ = deviceType));
		this.appState$ = this.store.select(appStateSelector);
		this.themeConfigs$ = this.themeService.configs;
		const urlParams = new URLSearchParams(window.location.search);
		const update = urlParams.get('update');
		if (update === 'true') {
			this.updateMode = true;
		}
	}

	ngOnInit() {
		addUtmParameters(this.router);
		const { required } = Validators;
		const { contactInfo } = this.user$;
		const { customerInfo } = this.user$;
		const dob = customerInfo?.dateOfBirth ?? '';
		const formattedDob = { dobYear: dob.substring(0, 4), dobMonth: dob.substring(5, 7), dobDay: dob.substring(8, 10) };
		const mobile = `${contactInfo?.mobilePhoneCode ?? ''}${contactInfo?.mobilePhone ?? ''}`;
		const formControls = {
			firstName: [customerInfo?.firstName ?? '', [required, validateNameWithMask()]],
			lastName: [customerInfo?.lastName ?? '', [required, validateNameWithMask()]],
			dateOfBirth: [formattedDob ?? '', [required]],
			mobile: [this.formatMobileNumber(mobile, true), [required, validateMobileWithMask()]],
			edrSetting: true,
			countdownSetting: true,
		};
		this.form = this.fb.group(formControls, { updateOn: 'change' });
	}

	stringifyDateOfBirth = (dateOfBirth: { dobYear: string; dobMonth: string; dobDay: string }) =>
		`${dateOfBirth.dobYear}-${dateOfBirth.dobMonth}-${dateOfBirth.dobDay}`;

	onSubmit() {
		const lastName: string = this.form.value.lastName?.trim();
		const dob = this.form.value.dateOfBirth;
		const edrSetting = this.form.value.edrSetting;
		const countdownSetting = this.form.value.countdownSetting;

		const fields: any = this.form.controls;
		Object.keys(fields).forEach((key) => {
			fields[key].markAsTouched();
			fields[key].markAsDirty();
			fields[key].updateValueAndValidity();
		});

		if (dob?.dobDay?.length < 2 || dob?.dobMonth?.length < 2) {
			this.form.controls['dateOfBirth'].setErrors({ required: true });
			return;
		}

		if (this.form.valid) {
			const firstName = this.form.value.firstName.trim();
			const dateOfBirth = this.stringifyDateOfBirth(this.form.value.dateOfBirth);
			const mobile = this.form.value.mobile.replace(/ /g, '');

			grecaptcha.enterprise.ready(() => {
				grecaptcha.enterprise
					.execute(this.env.reCaptchaKey, { action: 'create_registration' })
					.then((token: string) => {
						globalThis.v3token = token;
						this.store.dispatch(
							Actions.saveCustomerInfoForLogin({
								email: this.email$,
								password: this.user$.password,
								sessionInfoType: AppSettings.sessionInfoType[this.deviceType$],
								customerInfo: {
									dateOfBirth,
									firstName,
									lastName,
								},
								contactInfo: {
									emailAddress: this.email$,
									mobilePhone: mobile.substring(3),
									mobilePhoneCode: mobile.substring(0, 3),
								},
								preferences: {
									edrSetting: edrSetting,
									countdownSetting: countdownSetting,
								},
								updateMode: this.updateMode,
							})
						);
					});
			});
		}
	}

	formatMobileNumber(value: string | undefined, forceFormat = false) {
		const formattedValue = value.replace(/ /g, '');
		// If number begins with '64', format first section as 4 numbers
		if (value.startsWith('64')) {
			const partA = `${formattedValue.slice(0, 4)}`;
			const partB = `${formattedValue.slice(4, 7)}`;
			const partC = `${formattedValue.slice(7, 12)}`;
			if (formattedValue.length === 2) {
				value = `${partA} `;
			}
			if (formattedValue.length === 5) {
				value = `${partA} ${partB} `;
			}
			if (formattedValue.length === 9) {
				value = `${partA} ${partB} ${partC}`;
			}
			if (forceFormat && value) {
				value = `${partA} ${partB} ${partC}`;
			}
			return value;
		}
		// numbers beginning with '0' are formatted as 3 numbers
		else {
			const partA = `${formattedValue.slice(0, 3)}`;
			const partB = `${formattedValue.slice(3, 6)}`;
			const partC = `${formattedValue.slice(6, 12)}`;
			if (formattedValue.length === 3) {
				value = `${partA} `;
			}
			if (formattedValue.length === 6) {
				value = `${partA} ${partB} `;
			}
			if (formattedValue.length === 9) {
				value = `${partA} ${partB} ${partC}`;
			}
			if (formattedValue.length >= 11) {
				value = `${partA} ${partB} ${partC}`;
			}
			if (forceFormat && value) {
				value = `${partA} ${partB} ${partC}`;
			}

			return value;
		}
	}
}
