import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormService } from '@woolworthsnz/form';
import {
	codeVerifierSelector,
	emailSelector,
	sendMagicLinkFailedSelector,
	validateCustomerFailedSelector,
	verifyMagicLinkFailedSelector,
} from '../../state/app.selectors';
import * as Actions from '../../state/app.actions';
import { ActivatedRoute, Router } from '@angular/router';

import { AvailableApps, VerifyMagicLink } from '../../utils/type/registration.type';
import { Observable, delay } from 'rxjs';
import { addUtmParameters, useOIDCTheme } from '../../utils';
import { SessionState } from '../../state/app.state';
import { LoadingService } from '../../utils/loading.service';
import { EnvServiceProvider } from '../../env.service';
@Component({
	selector: 'registration-magic-link-expired',
	templateUrl: './magic-link-expired.component.html',
	styleUrls: ['./magic-link-expired.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [FormService, EnvServiceProvider, FormGroupDirective],
})
export class MagicLinkExpiredComponent implements OnInit, OnDestroy {
	email$: string;
	codeVerifier$: string;
	deviceType$: AvailableApps;
	activationState$: Observable<string>;
	token$: Observable<string>;
	verifyMagicLinkFailed$: Observable<string>;
	validateCustomerFailed$: Observable<string>;
	magicLinkInvalid$ = false;
	session$: SessionState;
	activated: string;
	form: UntypedFormGroup = new UntypedFormGroup({});
	sendMagicLinkFailed$: Observable<string>;
	isOIDCEnabled = useOIDCTheme();
	loading = false;

	constructor(
		public parent: FormGroupDirective,
		public formService: FormService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private readonly store: Store,
		private loadingService: LoadingService
	) {
		this.sendMagicLinkFailed$ = this.store.select(sendMagicLinkFailedSelector);
		this.store.select(emailSelector).subscribe((email) => (this.email$ = email));
		this.store.select(codeVerifierSelector).subscribe((codeVerifier) => (this.codeVerifier$ = codeVerifier));
	}

	listenToLoading(): void {
		this.loadingService.loadingSub.pipe(delay(0)).subscribe((loading) => {
			this.loading = loading;
		});
	}

	ngOnInit() {
		addUtmParameters(this.router);
		this.listenToLoading();
		// receiving secrets and then verify
		this.verifyMagicLinkFailed$ = this.store.select(verifyMagicLinkFailedSelector);
		this.validateCustomerFailed$ = this.store.select(validateCustomerFailedSelector);
		this.store.select(verifyMagicLinkFailedSelector).subscribe((failed) => {
			if (failed) {
				this.loadingService.setLoading(false);
			}
		});
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const otp = params.get('o');
			const email = params.get('e');
			const signature = params.get('s');
			if (otp && email && signature) {
				this.loadingService.setLoading(true);
				if (this.codeVerifier$) {
					const sessionToValidate: VerifyMagicLink = {
						email: email,
						otp: otp,
						signature: signature,
						codeVerifier: this.codeVerifier$,
						sessionInfoType: 'Browser',
					};
					this.store.dispatch(Actions.verifyMagicLink(sessionToValidate));
				} else {
					this.router.navigateByUrl('/edr/enter-email');
				}
			}
		});
	}
	resendMagicLink() {
		this.router.navigateByUrl('/edr/enter-email');
	}

	ngOnDestroy() {
		this.loadingService.setLoading(false);
	}
}
