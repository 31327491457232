<section class="main-section">
	<h1>Your Scan & Go App needs an update</h1>
	<p>
		Please head to the {{ this.deviceType$ === 'ScanAndGoIOS' ? 'App' : 'Google Play' }} Store and update your Scan & Go
		app to continue shopping with us.
	</p>
	<div class="form-actions">
		<button
			cdxButton
			class="submit-button"
			(click)="openMarket()"
			value="upgrade"
			tabindex="0"
			data-cy="'upgrade'"
			data-testid="upgradeButton"
			*ngIf="showButton"
		>
			Update
		</button>
	</div>
</section>
