import { AbstractControl, ValidatorFn } from '@angular/forms';

export class RegExMobile {
	static MOBILE = `^((0|64)(2[0-9]))\\d{6,9}$`;
}

export const validateMobileWithMask =
	(): ValidatorFn =>
	(control: AbstractControl): { [key: string]: any } | null => {
		const r = new RegExp(RegExMobile.MOBILE);
		const value = control.value.replace(/ /g, '');
		return r.test(value) ? null : { invalidMobile: control.value };
	};
