<ng-container *ngIf="isCharInput; then characterInput; else clickableInput"> </ng-container>

<ng-template #characterInput>
	<ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
	<ng-content select="[icon]"></ng-content>
	<ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
</ng-template>
<ng-template #clickableInput>
	<ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
	<ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
</ng-template>

<ng-content select="[validation]"></ng-content>
<ng-content select="[cdxFormHelpText]"></ng-content>

<ng-template #labelTemplate>
	<ng-content select="[label]"></ng-content>
</ng-template>

<ng-template #inputTemplate>
	<ng-container *ngIf="isTextarea; then multiLine; else singleLine"> </ng-container>
	<ng-template #multiLine>
		<textarea
			#input
			id="{{ id || formControlName }}"
			name="{{ id || formControlName }}"
			placeholder="{{ placeholder }}"
			[attr.data-size]="size"
			[attr.data-colour]="colour"
			[attr.readonly]="disabled"
			[attr.tabindex]="tabindex"
			[attr.maxLength]="maxlength"
			[attr.rowcount]="4"
			[attr.rows]="rows != undefined ? rows : null"
			[attr.cols]="columns != undefined ? columns : null"
			[attr.aria-activedescendant]="activeDescendant"
			[attr.inputmode]="inputMode"
			[ngClass]="{
				successIcon: successIcon
			}"
			[value]="inputValue"
			(blur)="onBlur($event)"
			(change)="onChange($event)"
			(keyup)="onKeyup(input.value)"
			(focus)="onFocus($event)"
		></textarea>
	</ng-template>
	<ng-template #singleLine>
		<ng-container *ngIf="!isCharInput; then checkbox; else text"> </ng-container>
		<ng-template #checkbox>
			<input
				#input
				type="{{ inputType }}"
				id="{{ id || formControlName }}"
				name="{{ id || formControlName }}"
				[attr.data-colour]="colour"
				[attr.data-size]="size"
				[attr.readonly]="disabled"
				[attr.tabindex]="tabindex"
				[attr.aria-activedescendant]="activeDescendant"
				[disabled]="disabled"
				[ngClass]="{ successIcon: successIcon }"
				[attr.data-cy]="formControlName"
				[value]="inputValue"
				(blur)="onBlur($event)"
				(change)="onChange($event)"
				(focus)="onFocus(input.value)"
				(click)="onClick()"
				
			/>
		</ng-template>
		<ng-template #text>
			<input
				#input
				type="{{ inputType }}"
				id="{{ id || formControlName }}"
				name="{{ id || formControlName }}"
				placeholder="{{ placeholder }}"
				[attr.autocomplete]="autocomplete"
				[attr.data-colour]="colour"
				[attr.data-size]="size"
				[attr.maxLength]="maxlength"
				[attr.readonly]="disabled"
				[attr.tabindex]="tabindex"
				[attr.aria-activedescendant]="activeDescendant"
				[attr.inputmode]="inputMode"
				[disabled]="disableInput"
				[ngClass]="{ successIcon: successIcon }"
				[attr.data-cy]="formControlName"
				[value]="inputValue"
				(blur)="onBlur($event)"
				(keyup)="onKeyup(input.value, $event)"
				(keydown)="onKeydown(input.value, $event)"
				(focus)="onFocus(input.value)"
				(click)="onClick()"
				(paste)="onPaste($event)"
			/>
		</ng-template>
	</ng-template>
</ng-template>
