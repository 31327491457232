import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroupDirective } from '@angular/forms';
import { DatalayerService, FormFieldValidationEvent } from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'form-input-error',
	template: ` <ng-content></ng-content> `,
	styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent implements OnInit {
	@Input() control: string;

	controlValue$: Observable<any>;
	controlStatus$: Observable<any>;
	formControl: AbstractControl | null;

	constructor(private fg: ControlContainer, private datalayerService: DatalayerService) {}

	ngOnInit(): void {
		if (this.form) {
			this.formControl = this.form.get(this.control);

			if (this.formControl instanceof AbstractControl) {
				this.controlValue$ = this.formControl.valueChanges;
				this.controlStatus$ = this.formControl.statusChanges;
				this.controlValue$.pipe(debounceTime(1000)).subscribe(this.pushToDataLayer);
			}
		}
	}

	get form() {
		return this.fg.formDirective ? (this.fg.formDirective as FormGroupDirective).form : null;
	}

	getErrorType(error: string) {
		if (!this.formControl || !this.formControl.errors) {
			return false;
		}

		if (Object.keys(this.formControl.errors).indexOf(error) > -1 || error === '*') {
			return true;
		}

		return false;
	}

	pushToDataLayer = () => {
		const hasError = this.formControl && this.formControl.errors;
		const data: FormFieldValidationEvent = {
			event: 'formFieldValidationEvent',
			fieldName: this.control,
			filedValue: this.formControl?.value,
			fieldValidated: hasError ? 'Fail' : 'Pass',
		};
		this.datalayerService.pushToDatalayer(data);
	};
}
