import { AppState, State, SessionState, UserState, EncryptedState } from './app.state';
import { createReducer, on } from '@ngrx/store';
import {
	checkIfRegistered,
	checkIfRegisteredFailed,
	login,
	loginFailed,
	loginSuccess,
	loginServerError,
	sendMagicLink,
	resendMagicLink,
	sendMagicLinkFailed,
	setDeviceType,
	setRegistered,
	verifyMagicLink,
	verifyMagicLinkFailed,
	verifyMagicLinkSuccess,
	resetUiError,
	saveCustomerInfo,
	clearUserFormdata,
	setApiNotAvailable,
	registerCustomerFailed,
	create,
	saveCustomerInfoForLogin,
	registerCustomerSuccess,
	sendForgotPasswordFailed,
	updatePasswordExpired,
	updatePassword,
	updatePasswordFailed,
	registeredInactive,
} from './app.actions';
import {
	AvailableApps,
	CustomerInfo,
	IfRegisteredResponse,
	LoginMagicLink,
	Sessions,
	Login,
} from '../utils/type/registration.type';
import { UpdatePassword } from './app.state';

const defaultSessionInfo = {
	sessionInfo: {
		// Pass these back to the native app after account linking
		sessionToken: undefined,
		sessionSecret: undefined,
	},
	cookieInfo: {
		// will be null for Mobile sessionInfoType
		cookieName: undefined,
		cookieValue: undefined,
	},
};

const defaultUser = {
	scvId: undefined,
	customerId: undefined,
	loyaltyCurrency: undefined,
};

export const defaultAppState: AppState = {
	isRegistered: undefined,
	checkIfRegisteredFailed: undefined,
	registeredInactive: undefined,
	deviceType: 'Trader', // other app should pass deviceType to update default state
	sendMagicLinkFailed: undefined,
	verifyMagicLinkFailed: undefined,
	loginFailed: undefined,
	loginFailedTitle: undefined,
	loginServerError: undefined,
	activationState: undefined,
	validateCustomerFailed: undefined,
	createActivationFailed: undefined,
	apiNotAvailable: undefined,
	updateCustomerFailed: undefined,
	updateCustomerSuccess: false,
	sendForgotPasswordFailed: undefined,
	updatePasswordExpired: undefined,
	updatePasswordFailed: undefined,
};

const initialState: State = {
	app: defaultAppState,
	session: {
		token: undefined,
		cards: [],
		...defaultSessionInfo,
	},
	user: defaultUser,
	encrypted: {
		codeVerifier: undefined,
	},
};

const resetError = {
	checkIfRegisteredFailed: undefined,
	registeredInactive: undefined,
	loginFailed: undefined,
	loginServerError: undefined,
	sendMagicLinkFailed: undefined,
	verifyMagicLinkFailed: undefined,
	validateCustomerFailed: undefined,
	createActivationFailed: undefined,
	alreadyLinkedToOtherEmailMsg: '',
	apiNotAvailable: undefined,
	registerCustomerFailed: undefined,
	updateCustomerFailed: undefined,
	updateCustomerSuccess: false,
	sendForgotPasswordFailed: undefined,
	updatePasswordFailed: undefined,
	updatePasswordExpired: undefined,
};

const appStateReducer = createReducer<AppState>(
	initialState.app,
	on(
		setDeviceType,
		(state, action: { deviceType: AvailableApps }): AppState => ({
			...state,
			deviceType: action.deviceType,
		})
	),
	on(
		setApiNotAvailable,
		(state, action: { msg: string }): AppState => ({
			...state,
			apiNotAvailable: action.msg,
		})
	),
	on(
		resetUiError,
		(state): AppState => ({
			...state,
			...resetError,
		})
	),
	on(
		checkIfRegistered,
		(state): AppState => ({
			...state,
			...resetError,
			isRegistered: undefined,

			activationState: undefined,
			sendMagicLinkFailed: undefined,
			verifyMagicLinkFailed: undefined,
		})
	),
	on(
		login,
		(state): AppState => ({
			...state,
			...resetError,
			activationState: undefined,
		})
	),
	on(
		loginFailed,
		(state, action: { title: string; msg?: string }): AppState => ({
			...state,
			loginFailed: action?.msg ?? undefined,
			loginFailedTitle: action?.title ?? undefined,
		})
	),
	on(
		loginServerError,
		(state, action: { msg?: string }): AppState => ({
			...state,
			loginServerError: action?.msg ?? undefined,
		})
	),
	on(
		registerCustomerFailed,
		(state): AppState => ({
			...state,
			updateCustomerSuccess: undefined,
			updateCustomerFailed: undefined,
		})
	),
	on(
		registerCustomerFailed,
		(state, action: { msg?: string }): AppState => ({
			...state,
			updateCustomerFailed: action?.msg ?? undefined,
			updateCustomerSuccess: false,
		})
	),
	on(
		registerCustomerSuccess,
		(state): AppState => ({
			...state,
			updateCustomerFailed: undefined,
			updateCustomerSuccess: true,
		})
	),
	on(
		checkIfRegisteredFailed,
		(state, action: { msg?: string }): AppState => ({
			...state,
			checkIfRegisteredFailed: action?.msg ?? undefined,
		})
	),
	on(
		setRegistered,
		(state, action: IfRegisteredResponse): AppState => ({
			...state,
			isRegistered: !action.isAvailable,
		})
	),
	on(
		registeredInactive,
		(state, action: { msg?: string }): AppState => ({
			...state,
			registeredInactive: action.msg,
		})
	),
	on(
		sendMagicLink,
		(state): AppState => ({
			...state,
			...resetError,
			activationState: undefined,
		})
	),
	on(
		sendMagicLinkFailed,
		(state, action: { msg?: string }): AppState => ({
			...state,
			sendMagicLinkFailed: action.msg,
		})
	),
	on(
		sendForgotPasswordFailed,
		(state, action: { msg?: string }): AppState => ({
			...state,
			sendForgotPasswordFailed: action.msg,
		})
	),
	on(
		updatePasswordExpired,
		(state, action: { title?: string; msg?: string }): AppState => ({
			...state,
			updatePasswordExpired: action.msg,
		})
	),

	on(
		updatePasswordFailed,
		(state, action: { title?: string; msg?: string }): AppState => ({
			...state,
			updatePasswordFailed: action.msg,
		})
	),
	on(
		verifyMagicLinkFailed,
		(state, action: { msg?: string }): AppState => ({
			...state,
			verifyMagicLinkFailed: action.msg,
		})
	),
	on(
		verifyMagicLink,
		(state): AppState => ({
			...state,
			...resetError,
			verifyMagicLinkFailed: undefined,
			activationState: undefined,
		})
	),
	on(
		saveCustomerInfo,
		(state): AppState => ({
			...state,
			...resetError,
		})
	)
);

const resetSession = (state: SessionState): SessionState => ({
	...state,
	token: undefined,
	cards: [],
	...defaultSessionInfo,
});

const saveSession = (state: SessionState, action: Sessions): SessionState => ({
	...state,
	token: action.token,
	sessionInfo: {
		...action.sessionInfo,
	},
	cookieInfo: {
		...action.cookieInfo,
	},
});

const resetSessionInfo = (state: SessionState): SessionState => ({
	...state,
	token: undefined,
	...defaultSessionInfo,
});

const resetUser = (state: UserState): UserState => ({
	...state,
	...defaultUser,
});

const sessionStateReducer = createReducer<SessionState>(
	initialState.session,
	on(checkIfRegistered, resetSession),
	on(login, resetSession),
	on(loginSuccess, saveSession),
	on(sendMagicLink, resetSessionInfo),
	on(verifyMagicLinkFailed, resetSession),
	on(verifyMagicLinkSuccess, saveSession)
);

const userStateReducer = createReducer<UserState>(
	initialState.user,
	on(
		checkIfRegistered,
		(state, action: { email: string }): UserState => ({
			...defaultUser,
			email: action.email,
		})
	),
	on(
		sendMagicLink,
		(state, action: LoginMagicLink): UserState => ({
			...state,
			email: action.email,
		})
	),
	on(
		resendMagicLink,
		(state, action: LoginMagicLink): UserState => ({
			...state,
			email: action.email,
		})
	),

	on(loginSuccess, resetUser),
	on(clearUserFormdata, (): UserState => initialState.user),
	on(
		saveCustomerInfo,
		(state, action: CustomerInfo): UserState => ({
			...state,
			...action,
		})
	),
	on(
		create,
		(state, action: Login): UserState => ({
			...state,
			...action,
		})
	),
	on(
		updatePassword,
		(state, action: UpdatePassword): UserState => ({
			...state,
			...action,
		})
	),
	on(
		saveCustomerInfoForLogin,
		(state, action: CustomerInfo): UserState => ({
			...state,
			...action,
		})
	)
);

const encryptedStateReducer = createReducer<EncryptedState>(
	initialState.encrypted,
	on(
		sendMagicLink,
		(state, action: LoginMagicLink): EncryptedState => ({
			...state,
			codeVerifier: action.codeVerifier,
		})
	),
	on(
		sendMagicLink,
		(state, action: LoginMagicLink): EncryptedState => ({
			...state,
			codeVerifier: action.codeVerifier,
		})
	)
);

export const appReducer = (state, action) => appStateReducer(state, action);
export const sessionReducer = (state, action) => sessionStateReducer(state, action);
export const userReducer = (state, action) => userStateReducer(state, action);
export const encryptedReducer = (state, action) => encryptedStateReducer(state, action);
