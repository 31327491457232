import {
	ChangeDetectorRef,
	Component,
	ContentChildren,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	QueryList,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GlobalErrorHandlerService } from '@woolworthsnz/styleguide';
import { ValidationError, ValidationErrorModel } from '../../../../../../apps/edr/src/app/utils/type/registration.type';

import { Observable } from 'rxjs';
import { FormService } from '../../services';
import { InputComponent } from '../input/input.component';

@UntilDestroy()
@Component({
	exportAs: 'cdxForm',
	selector: 'form-form, [cdxForm]',
	template: `
		<cdx-alert
			[type]="error?.type"
			[description]="error?.errors"
			[title]="error?.message"
			*ngIf="error && errorStyle === 'default'"
			scrollIntoView
		>
		</cdx-alert>
		<ng-content></ng-content>
	`,
	styleUrls: ['./form.component.scss'],
	providers: [FormService],
})
export class FormComponent implements OnInit {
	@ContentChildren(InputComponent) inputs: QueryList<InputComponent>;

	// TODO: Add some form of style for discreet. Currently it just doesnt show the alert
	@Input() errorStyle: 'default' | 'discreet' = 'default';
	@Input() formGroup: UntypedFormGroup;
	@Input() formAction: string;
	@Output() formSubmitted: EventEmitter<any> = new EventEmitter();
	@Output() submissionSuccess: EventEmitter<any> = new EventEmitter();

	canSubmit = false;
	error?: {
		type?: string;
		message: string;
		errors?: Array<ValidationError>;
	};

	submitted$: Observable<boolean>;
	errored$: Observable<any>;
	completed$: Observable<boolean>;

	constructor(
		public formService: FormService,
		public globalErrorService: GlobalErrorHandlerService,
		private ref: ChangeDetectorRef
	) {}

	@Input() transformer: (any: any) => any = (value) => value;

	@HostListener('submit')
	onSubmit() {
		if (this.formGroup.valid) {
			this.formSubmitted.emit();

			if (this.formAction) {
				this.formService.submitForm(this.formAction, this.transformer(this.formGroup.getRawValue()));
			}
		} else {
			this.validateAllFormFields(this.formGroup);
		}
	}

	

	ngOnInit() {
		this.errored$ = this.formService.errored$;
		this.submitted$ = this.formService.submitted$;

		this.errored$.pipe(untilDestroyed(this)).subscribe(this.onError);

		if (this.formGroup) {
			this.formGroup.statusChanges.pipe(untilDestroyed(this)).subscribe(this.onFormStatusChanges);
		}

		this.formService.result$.pipe(untilDestroyed(this)).subscribe(this.onFormResult);
	}

	// Ensure this stays as an arrow function so
	// that the context of `this` isn't lost
	// https://stackoverflow.com/questions/49868488/angular-httpclient-error-handling-requiring-zone-run-to-update-ui
	onError = (err: { error: ValidationErrorModel }) => {
		if (!err) {
			this.error = undefined;
		} else {
			const { error } = err;
			this.error = {
				type: 'error',
				message: error?.message || '',
				errors: error?.errors,
			};
		}

		this.ref.markForCheck();
	};

	onFormResult = (result: any) => {
		this.submissionSuccess.emit(result);
	};

	onFormStatusChanges = (status: any) => {
		this.canSubmit = status === 'VALID';

		if (!(<any>this.ref)['destroyed']) {
			this.ref.markForCheck();
		}
	};

	validateAllFormFields(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field);

			if (control instanceof UntypedFormControl) {
				control.markAsTouched();
				control.markAsDirty();
				(<EventEmitter<any>>control.statusChanges).emit(control.status);
				(<EventEmitter<any>>control.valueChanges).emit(control.value);
			} else if (control instanceof UntypedFormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}
}
